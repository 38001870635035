import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
const SideMenu = () => {

    const handleLogout = () => {
        localStorage.clear();
         window.location.href = '/';
    };


    
    return (
        <>
            <div className="mt-5">
                <aside className="w-full h-68 md:w-[285px] md:h-screen border rounded-[10px] mt-[25px] p-8">
                    <ul className="flex flex-col ">
                        <li className="">
                            <Link to="/personal-detail">
                                <div className="flex flex-row items-center h-12 px-4  rounded-lg text-black-300 active:text-[#415553] hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined">
                                            person
                                        </span>                </span>
                                    <span className="ml-3   text-[#415553]">Personal Information</span>
                                </ div>
                            </Link>
                        </li>
                        <li className="my-px">
                            <Link to="/login-information">
                                <div className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553] hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined ">
                                            info
                                        </span>                                </span>
                                    <span className="ml-3 text-[#415553] ">Login Information</span>
                                </div>
                            </Link>
                        </li>
                        <li className="my-px">
                            <Link to="/payment-information">
                                <div className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553]  hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined">
                                            account_balance
                                        </span>                                </span>
                                    <span className="ml-3 text-[#415553]">Payment Method</span>
                                </div>
                            </Link>
                        </li>
                        <li className="my-px">
                            <Link to="/notification">
                                <div className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553]  hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined">
                                            notifications
                                        </span>                                </span>
                                    <span className="ml-3 text-[#415553]">Notification</span>
                                </div>
                            </Link>
                        </li>

                        <li className="my-px">
                            <Link to="/review">
                                <div className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553]  hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined">
                                            Reviews
                                        </span>                                </span>
                                    <span className="ml-3 text-[#415553]">Reviews</span>
                                </div>
                            </Link>
                        </li>

                        <li className="my-px">
                            <Link to="/recentlyreview">
                                <div className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553]  hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined">
                                            view_list
                                        </span>                                </span>
                                    <span className="ml-3 text-[#415553]">  Recently Viewed</span>
                                </div>
                            </Link>
                        </li>

                        <li className="my-px">
                            <Link to="/booked">
                                <div className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553]  hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined">
                                            book
                                        </span>                                
                                        </span>
                                    <span className="ml-3 text-[#415553]"> Booked </span>
                                </div>
                            </Link>
                        </li>

                        <li className="my-px">
                            <Link to="/sitesetting">
                                <div className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553]  hover:text-[#2A3B39]">
                                    <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                        <span className="material-symbols-outlined">
                                            settings
                                        </span>                                </span>
                                    <span className="ml-3 text-[#415553]">Site Settings</span>
                                </div>
                            </Link>
                        </li>

                        <li className="my-px">
                            <button onClick={handleLogout} className="flex flex-row items-center h-12 px-4 rounded-lg text-black-300 active:text-[#415553]  hover:text-[#2A3B39] w-full">
                                <span className="flex items-center justify-center text-lg text-[#415553] hover:text-[#2A3B39]">
                                    <span className="material-symbols-outlined">
                                        logout
                                    </span>
                                </span>
                                <span className="ml-3 text-[#415553]">Logout</span>
                            </button>
                        </li>
                    </ul>
                </aside>
            </div >
        </>
    )
}

export default SideMenu
