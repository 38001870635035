import React, { useState } from 'react'
import SideMenu from '../side-menu'

const LoginInformation = () => {
    
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
      <div className='grid grid-cols-1 md:grid-cols-2 mx-5 md:mx-0 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-740px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)] pb-10'>
        <div className='block mt-20 md:mt-[95px]'>
          <SideMenu />
        </div>
        <div className="md:-ml-16 lg:-ml-52 xl:-ml-72 mt-5 md:mt-[118px] border rounded-[10px] p-10">
          <div className='grid grid-cols-2'>
            <h1 className='text-[18px] font-semibold text-[#415553]'>Login Information</h1>
            <h1 className='text-[18px] font-semibold text-[#415553] mb-5  cursor-pointer'>Edit</h1>
          </div>
            <form action="" className='flex flex-col space-y-5'>
            <label htmlFor="">Password</label>
            <input type="text" placeholder='********' className='w-full md:w-[300px] h-[40px] border-2 indent-2 md:indent-4 placeholder:text-[18px]' />
            <div>
              <h3 className='text-[16px] text-[#415553]'>Deactivate</h3>
              <p className='text-[14px] text-[#415553]'>Deactivate your account means that your account will no longer be available. you will not be able to sing in and your profile will not be accessible. Any reviews,Photos,and tips that you have contributed may continue to be displayed on the site</p>
            </div>
            <button className=' bg-red-600 p-2 rounded-md w-full md:w-[300px] h-[40px] text-[#fff] hover:bg-[#2d0f0ff8]'>Deactivate Account</button>
              </form>
          </div>
        </div>
    </>
  )
}

export default LoginInformation
