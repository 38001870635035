// Calendar.js
import React, { useState } from 'react';
import { useActivityAvailableStore } from '../../store/store';

const Calendar = ({ events , selectionMonthData, selectionData }) => {
    

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [isCalendarVisible, setCalendarVisible] = useState(true);
    // const { startTime } = selectionData;

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();


    const { calenderSelectedDate, selectedMonthRange, isSelectedDate } = useActivityAvailableStore();



    const monthsAndYears = Array.from({ length: 12 }, (_, i) => {
        const year = currentYear + Math.floor(i / 12);
        const month = i % 12;
        return {
            value: `${year}-${month < 9 ? '0' + (month + 1) : month + 1}`,
            label: new Date(year, month, 1).toLocaleDateString('default', {
                month: 'long',
                year: 'numeric',
            }),
        };
    });

    const renderMonthsYearsDropdown = () => {
        return (
            <select
                className="px-4 py-2  bg-[#f5f5f5] rounded mr-2" 
                value={`${currentYear}-${currentMonth < 9 ? '0' + (currentMonth + 1) : currentMonth + 1}`}
                onChange={(e) => handleDateChange(e.target.value)}
            >
                {monthsAndYears.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        );
    };



    const renderCalendar = () => {
        const calendar = [];
        let day = 1;
        const today = new Date();
        const isCurrentMonth = currentDate.getMonth() === today.getMonth();
        const isCurrentYear = currentDate.getFullYear() === today.getFullYear();

        // Fill empty days before the first day of the month
        for (let i = 0; i < firstDayOfMonth; i++) {
            calendar.push(<div key={`empty-${i}`} className="border p-2 m-1 w-12 h-12 xl:w-14 xl:h-14"></div>);
        }

        // Fill in days of the month with events
        while (day <= daysInMonth) {
            const formattedDay = day < 10 ? `0${day}` : `${day}`;
            const formattedDate = `${currentYear}-${currentMonth + 1}-${formattedDay}`;
            const event = events.find((event) => event.date === formattedDate);

            const isFutureDate = new Date(formattedDate) >= today; // Check if date is today or in the future
            const isDisabled = !isFutureDate || isDateAvailable(formattedDate);

            calendar.push(
                <div
                    key={day}
                    className={`border p-2 m-1 w-12 h-12 xl:w-14 xl:h-14 cursor-pointer ${selectedDate === formattedDate ? ' !bg-slate-500' : ''
                        } ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => isFutureDate && !isDisabled && handleDateClick(formattedDate)}
                >
                    <p>{day}</p>
                    {event ? <p>Cost: ${event.cost}</p> : null}
                </div>
            );

            day++;
        }

        return calendar;
    };


    const isDateAvailable = (date) => {
        return selectionMonthData.includes(date);
    };

    const handleMonthChange = (month) => {
        setCurrentDate(new Date(currentYear, month, 1));
        setSelectedDate(null);
    };

    const handleYearChange = (year) => {
        setCurrentDate(new Date(year, currentMonth, 1));
        setSelectedDate(null);
    };

    const handlePrevMonth = () => {
        setCurrentDate(new Date(currentYear, currentMonth - 1, 1));
        setSelectedDate(null);
        const newDate = new Date(currentYear, currentMonth - 1, 1);
        captureMonthRange(newDate);
    };

    const handleDateChange = (dateValue) => {
        const [year, month] = dateValue.split('-');
        setCurrentDate(new Date(parseInt(year), parseInt(month) - 1, 1));
        setSelectedDate(null);
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentYear, currentMonth + 1, 1));
        setSelectedDate(null);
        const newDate = new Date(currentYear, currentMonth + 1, 1);
        captureMonthRange(newDate);
    };

    const handleDateClick = (date) => {
        const formattedDate = new Date(date).toLocaleDateString('en-CA');
        setSelectedDate(formattedDate);
        calenderSelectedDate(formattedDate);
        setCalendarVisible(false); // Hide the calendar when a date is selected
        isSelectedDate(true)
    };

    const handleShowCalendar = () => {
        setCalendarVisible(true); // Show the calendar when the "Show" button is clicked
    };

    const captureMonthRange = (newDate) => {
        const firstDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
        const lastDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);

        selectedMonthRange(firstDate.toLocaleDateString('en-CA'),lastDate.toLocaleDateString('en-CA'));
        // console.log('First Date:', firstDate.toLocaleDateString('en-CA'));
        // console.log('Last Date:', lastDate.toLocaleDateString('en-CA'));
    };

    return (
        <div className="container mx-auto mt-8">
            {isCalendarVisible ? (
                <div>
                    <div className="flex justify-center items-center mb-4">
                        <div className='flex justify-between items-center'>
                            <button onClick={handlePrevMonth} className="p-[3px] w-10 md:w-16 lg:w-20 bg-[#f5f5f5] rounded mr-2 ">
                                <span class="material-symbols-outlined mt-1">
                                    keyboard_double_arrow_left
                                </span>
                            </button>
                            {renderMonthsYearsDropdown()}
                            {/* {renderYearsDropdown()} */}
                            <button onClick={handleNextMonth} className="p-[3px] w-10 md:w-16 lg:w-20 bg-[#f5f5f5] rounded mr-2">
                                <span class="material-symbols-outlined mt-1">
                                    keyboard_double_arrow_right
                                </span>
                            </button>
                        </div>
                        <h1 className="text-2xl font-bold">
                            {/* {currentDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })} */}
                        </h1>
                    </div>
                    {selectionMonthData && selectionMonthData.length > 0 ? (
                        <div className="flex flex-wrap">{renderCalendar()}</div>
                    ) : (
                        <h1 className='text-center text-[#415333] text-[22px] py-20'>Not Available for this month</h1>
                    )}
                    {selectedDate && (
                        <div className="mt-4">
                            <h2 className="text-xl font-bold mb-2">Selected Date: {selectedDate}</h2>
                            <p>Events for selected date:</p>
                            <ul>
                                {events
                                    .filter((event) => event.date === selectedDate)
                                    .map((event, index) => (
                                        <li key={index}>Cost: ${event.cost}</li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </div>
            ) : (
                <div className="mt-4">
                    <h2 className="text-xl font-bold mb-2">Selected Date: {selectedDate}</h2>
                    {selectionData &&
                    <button  className="bg-blue-500 text-white px-3 py-2 rounded">
                            {selectionData?.startTime}
                    </button>
                    }
                        <div className='flex space-x-1 mt-1 cursor-pointer' onClick={handleShowCalendar}>
                            <span class="material-symbols-outlined text-[#1D57C7] !text-[16px] mt-0.5">
                                chevron_left
                            </span>
                            <p className='text-[#1D57C7] text-[12px]'>back to calendar</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Calendar;
