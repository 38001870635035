import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";

const Footer = () => {
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    const fakeDatafetch = () => {
      setTimeout(() => {
        setIsloading(false);
      }, 5000)
    }
    fakeDatafetch();
  }, {})


  return isloading ? (
    <Loader />
  ) : (
    <>
      <div className="bg-[#415553] w-full h-auto relative bottom-0 ">
        <div className="mx-5 py-10 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">
          <div className="grid grid-cols-1 md:grid-cols-4 text-[#fff]">
            <ul className="">
            <li className="cursor-pointer group flex items-center hover:text-[#FFB800] duration-300 transform translate-x-0 hover:ml-[10px] w-[180px]">
                <span className="mt-[5px]">
                  <span className="material-symbols-outlined opacity-0 group-hover:opacity-100 transition-opacity">
                    keyboard_double_arrow_right
                  </span>
                </span>
                <Link to="/privacy-statement"><span className="text-base">Privacy Statement</span></Link>
              </li>

              <li className="cursor-pointer group flex items-center hover:text-[#FFB800] duration-300 transform translate-x-0 hover:ml-[10px] w-[180px]">
                <span className="mt-[5px]">
                  <span className="material-symbols-outlined opacity-0 group-hover:opacity-100 transition-opacity">
                    keyboard_double_arrow_right
                  </span>
                </span>
                <Link to="/about"><span className="text-base">About Modon</span></Link>
              </li>
            </ul>
            <ul className="">
            <li className="cursor-pointer group flex items-center hover:text-[#FFB800] duration-300 transform translate-x-0 hover:ml-[10px] w-[180px]">
                <span className="mt-[5px]">
                  <span className="material-symbols-outlined opacity-0 group-hover:opacity-100 transition-opacity">
                    keyboard_double_arrow_right
                  </span>
                </span>
                <Link to="/contact"><span className="text-base">Contact</span></Link>
              </li>
              <li className="cursor-pointer group flex items-center hover:text-[#FFB800] duration-300 transform translate-x-0 hover:ml-[10px] w-[180px]">
                <span className="mt-[5px]">
                  <span className="material-symbols-outlined opacity-0 group-hover:opacity-100 transition-opacity">
                    keyboard_double_arrow_right
                  </span>
                </span>
                <Link to="/sitemap"><span className="mb-1">Site Map</span></Link>
              </li>
            </ul>
            <ul className="">
            <li className="cursor-pointer group flex items-center hover:text-[#FFB800] duration-300 transform translate-x-0 hover:ml-[10px] w-[180px]">
                <span className="mt-[5px]">
                  <span className="material-symbols-outlined opacity-0 group-hover:opacity-100 transition-opacity">
                    keyboard_double_arrow_right
                  </span>
                </span>
                <Link to="/travelagent"> <span className="text-base">Travel Agent</span></Link>
              </li>
              


              <li className="cursor-pointer group flex items-center hover:text-[#FFB800]  duration-300 transform translate-x-0 hover:ml-[10px] w-[180px]">
                <span className="mt-[5px]">


                  <span className="material-symbols-outlined opacity-0 group-hover:opacity-100 transition-opacity">
                    keyboard_double_arrow_right
                  </span>
                </span>
                <Link to="/news"> <span className="text-base">News </span> </Link>
              </li>
            </ul>
            <ul className="">
            <li className="cursor-pointer group flex items-center hover:text-[#FFB800] duration-300 transform translate-x-0 hover:ml-[10px] w-[180px]">
                <span className="mt-[5px]">
                  <span className="material-symbols-outlined opacity-0 group-hover:opacity-100 transition-opacity">
                    keyboard_double_arrow_right
                  </span>
                </span>
                <span className="text-base">Follow Us On</span>
              </li>
              <div className="ml-5 grid grid-cols-3 w-[100px]">

              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30" fill="#ffffff">
    <path d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z"></path>
</svg>


<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30" fill="#ffffff">
    <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
</svg>

               
              </div>
            </ul>
          </div>

          <div className="w-full border-[1px] mt-10"></div>
          <h1 className="mt-5 text-[14px] text-[white] text-center">
            Copyright @ MODON -Designed by Meerana Technologies LLP
          </h1>
        </div>
      </div>
    </>
  );
};

export default Footer;
