import React, { useState, useEffect } from 'react';
import LandingPage from '../landing-page';
import Loader from '../loader/loader';

const Home = (props) => {
    const addToWishlist = props.addToWishlist;
    const removeFromWishlist = props.removeFromWishlist;
    const addToRecentlyViewed=props.addToRecentlyViewed
  
  const [bannerSection, setBannerSection] = useState([]);
  const [staticSection, setStaticSection] = useState({});

  const homeStacticSection = () => (

    fetch(`http://20.174.26.6:4000/api/home/heroSection`)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error("Unable to fetch data")
        }
      }).then((data) => {
        setBannerSection(data?.staticSections);
        setStaticSection(data?.staticSections[0])

      }).catch((err) => {
        console.log(err);
      })
  )


  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % staticSection?.image?.length);
    }, 7000);

    return () => clearInterval(interval);
  }, [staticSection?.image?.length]);

  useEffect(() => {
    const savedIndex = localStorage.getItem('heroBannerIndex');
    if (savedIndex !== null) {
      setCurrentImageIndex(parseInt(savedIndex, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('heroBannerIndex', currentImageIndex);
    homeStacticSection();
  }, [currentImageIndex]);

const [isloading, setIsloading] = useState(true);

useEffect(() =>{
  const fakeDatafetch = () =>{
    setTimeout(() =>{
      setIsloading(false);
    },2000)
  }
  fakeDatafetch();
},{})

  return  isloading ? (
    <Loader/>
  ):(
    <>
      <section className="relative w-full h-[100vh] ">
      <div className='absolute inset-0 bg-black bg-opacity-60 z-10 transition duration-300 ease-in-out '></div>
        {staticSection?.image?.map((image, index) => (
          <img
            key={index}
            src={`./assets/backgrounds/${image?.imageUrl}`}
            alt={`Slide ${index + 1}`}
            className={`absolute top-0 left-0 w-full object-cover h-[100vh] transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
              }`}
          />
        ))}



        <div className='absolute top-[35vh] mx-5 md:mx-0 xl:left-[calc((100%-1200px)/2)] xl:right-[calc((100%-1200px)/2)]'>
          <img src="/assets/shapes/banner-two__flotated-text.png" alt="" />
        </div>
        <div className='absolute top-[30vh] z-30 md:top-[40vh] mx-5 xl:mx-0 xl:left-[calc((100%-1100px)/2)] xl:right-[calc((100%-1100px)/2)]'>
          <h2 className='text-[28px] md:text-[48px] font-bold  text-[#fff]'>{staticSection?.contentTitle}</h2>
          <p className='text-[16px] md:text-[18px] font-regular leading-5 md:leading-8  text-justify text-[#fff]'>{staticSection?.contentDesc}</p>
        </div>
      </section>

      <section className='w-full h-auto relative'>
        <LandingPage staticSections={bannerSection} addToWishlist={addToWishlist}
          removeFromWishlist={removeFromWishlist}  addToRecentlyViewed={addToRecentlyViewed} />
      </section>
      </>
  );
}

export default Home
