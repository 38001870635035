import React, { useState } from 'react'
import SideMenu from '../side-menu'

const Notification = () => {

    const [navbar, setNavbar] = useState(false);

  return (
    <>
          <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
          <div className='grid grid-cols-1 md:grid-cols-2 mx-5 pb-10 md:mx-0 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-740px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]'>
        <div className='block mt-20 md:mt-[95px]'>
                  <SideMenu />
              </div>
              <div className="md:-ml-16 lg:-ml-52 xl:-ml-72 mt-5 md:mt-[118px] border rounded-[10px] p-10">
                  <div className='grid grid-cols-3'>
                      <h1 className='text-[18px] font-semibold text-[#415553] mb-5'>Notification</h1>
                  </div>
                  <div>
                      <p className='text-[16px] text-[#415553] mb-5'>Modon will send you weekly email with travel inspiration,tips,recommendations
                          and company updates</p>
                  </div>
                </div>
            </div>
    </>
  )
}

export default Notification
