import { create } from "zustand";

export const useCurrencyStore = create((set,get)=>({
    currency: "AED",
    currencyRate: 1,
    getCurrency:()=>{
        const storedCurrency = JSON.parse(localStorage.getItem('currency'));
        if (storedCurrency && get().currency !== storedCurrency.currency) {
            set({currency: storedCurrency.currency});
            set({currencyRate:storedCurrency.currencyRate})
        }
    },

    changeCurrency:(currency,user_id)=>{
        var _body =JSON.stringify({currency:currency,userId:user_id});
        fetch(`http://20.174.26.6:4000/api/currency/set-currency`,{
            method:"POST",
            headers: {
                "Content-Type": "application/json", // Set content type to JSON
            },
            body:_body
        })
        .then(res=>res.json())
        .then(data=>{
            if(get().currency !== data.result?.currency){

                set({currency: data.result?.currency});
                set({currencyRate:data.result?.currencyRate})
                let currency ={
                    currency:data.result?.currency,
                    currencyRate:data.result?.currencyRate
                }
                localStorage.setItem("currency",JSON.stringify(currency))
            }
        })

    }


}));

export const useActivityAvailableStore = create((set,get)=>({
    selectedDate : null,
    firstDate: null,
    lastDate: null,
    isSelected : false,

    calenderSelectedDate:(date)=>{
        set({selectedDate:date});
    },

    selectedMonthRange:(firstDate,lastDate)=>{
        set({firstDate:firstDate});
        set({lastDate:lastDate})
    },

    isSelectedDate:(isSelect)=>{
        set({ isSelected: isSelect })
    }

}));


export const useActivityStore = create((set,get)=>({

    cart:[],
    isCartAffected:0,
    AlreadyItemInCart:false,

    // initiatecart: (user_id) => {
    //     get().initiatecartlist(user_id);
    //   },
    initiatecartlist:(user_id,token)=>{
        const cart=[];

        fetch(`http://20.174.26.6:4000/api/cart/get-cart-details`,
        {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json' 
            },
        })
            .then((res)=>res.json())
            .then((data)=>{
                if (data && data.result.length > 0) {
                    const cart_arr = data.result;
                    cart_arr.map((element) => {
                        cart.push(element);
                        return null; 
                    });
                    set({ cart: cart });
                } else set({ cart: [] });

            }).catch((err)=>{
                console.log(err)
            })

    },
    initiatecartapi:(apiUrl,bodyParams,token)=>{
        var raw = bodyParams;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(raw)
        };

        fetch(apiUrl,requestOptions)
            .then((response)=>response.json())
            .then((data)=>{
                console.log(JSON.stringify(data));
                if(data.isSuccess){
                    window.location='/checkout'
                    let isCartAffected = get().isCartAffected;
                    isCartAffected++;
                    set({isCartAffected});
                    // get().initiatecartlist(raw?.userInfo?.email);
                }else if(!data.isSuccess){
                    if(data.itemInCart){
                        set({AlreadyItemInCart:true});
                    }
                }
               
            })
            .catch((err) => {
                console.log(err.message);
                let isCartAffected = get().isCartAffected;
                isCartAffected++;
                set({ isCartAffected });
        
            });
    },

    removeitemfromcart:(apiUrl,jwtToken)=>{

        fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwtToken,
              },
        })
        .then((res)=>res.json())
        .then((data)=>{
            console.log(JSON.stringify(data));

            if(data.isSuccess){
                let isCartAffected = get().isCartAffected;
                isCartAffected++;
                set({isCartAffected});
                // get().initiatecartlist(raw?.userInfo?.email);
            }
        }).catch((err)=>{
            console.log(err.message);
            let isCartAffected = get().isCartAffected;
            isCartAffected++;
            set({ isCartAffected });
        })

    },


    addtocart:(activityData,token)=>{
        const cart = get().cart;

        if(activityData){
            cart.push({...activityData?.productInfo})
            get().initiatecartapi('http://20.174.26.6:4000/api/cart/add-to-cart',activityData,token); 
        }

        set({ cart: [...cart] });
    },

    removefromcart:(productId,user_id,jwtToken)=>{
        get().removeitemfromcart(`http://20.174.26.6:4000/api/cart/remove-from-cart?productId=${productId}`,jwtToken)
    
    }
}));








export const useWishlistStore = create((set, get) => ({
    wishlist: [],
    isWishlistAffected: 0,

    initiateWishlist: (user_id) => {
        const wishlist = [];

        fetch(`http://20.174.26.6:4000/api/wishlist/get-wishlish-info?userId=${user_id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.result.length > 0) {
                    const wishlist_arr = data.result;
                    wishlist_arr.map((element) => {
                        wishlist.push(element);
                        return null;
                    });
                    set({ wishlist: wishlist });
                } else set({ wishlist: [] });
            })
            .catch((err) => {
                console.log(err);
            });
    },

    addToWishlist: (productInfo) => {
        const wishlist = get().wishlist;

        if (productInfo) {
            wishlist.push({ ...productInfo });
            get().initiateWishlistApi('http://20.174.26.6:4000/api/wishlist/add-to-wishlist', productInfo);
        }

        set({ wishlist: [...wishlist] });
    },

   
    
}));
















  
