import React,{ useEffect } from "react";

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="">
                <img
                    src={"./assets/backgrounds/2.png"}
                    alt=''
                    className={`relative top-0 left-0 right-0 w-full object-cover h-[70vh] transition-opacity opacity-100 duration-1000`}
                />
                {/* lg:left-[calc((100%-1100px)/2)] lg:right-[calc((100%-1100px)/2)] */}
                <div className='absolute top-0 grid  w-full justify-items-center content-center h-[70vh] '>
                    <h2 className='text-[22px] md:text-[32px] font-semibold capitalize text-[#fff]'>Privacy and Cookies Statement</h2>
                    <ul class="flex text-white font-bold md:text-[20px] text-[12px]">
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'>Homr &nbsp;</li>/
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500 px-2'><span>Pages</span></li>/
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'><span>&nbsp; Privacy & Cookies Statement</span></li>
                    </ul>
                </div>
            </div>
            <div className="lg:px-[calc((100%-1200px)/2)] px-5">
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    Welcome to Modon global. This Privacy and Cookies Statement explains how we collect, use, and protect your personal information when you use our services.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400]] md:mt-10 mt-3">
                    By accessing or using our Website, you consent to the practices described in this Privacy and Cookies Statement. If you do not agree with our practices, please do not use our Website.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    1. Information We Collect We may collect various types of information from users of our Website, including:
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>1.1 Personal Information</span> <br />
                    When you create an account or make a reservation, we may collect personal information such as your name, email address, phone number, and payment details.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>1.2 Non-Personal Information</span> <br />
                    We may collect non-personal information, including your IP address, browser type, operating system, and browsing habits, which helps us improve our services.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    2. How We Use Your Information We may use your information for the following purposes:
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>2.1 Providing Services</span> <br />
                    To process reservations, provide customer support, and personalize your experience on our Website.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>2.2 Communication</span> <br />
                    To send you booking confirmations, updates, and marketing materials (if you opt-in).
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>2.3 Analytics</span> <br />
                    To analyze user behavior and improve our Website's functionality and content.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>3. Cookies</span> <br />
                    We use cookies and similar technologies to enhance your experience on our Website. Cookies are small text files that are stored on your device when you visit our Website. You can manage your cookie preferences through your browser settings.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>4. Sharing Your Information</span> <br />
                    We may share your information with third parties under the following circumstances:
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    4.1 To facilitate reservations and payments. With trusted service providers for website maintenance, marketing, and analytics. When required by law or to protect our rights, privacy, safety, or property.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>5. Your Privacy Choices You have the right to:</span> <br />
                    Access, correct, or delete your personal information. Opt out of receiving marketing communications. Disable cookies through your browser settings.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px]] font-[400] md:mt-10 mt-3">
                    <span>6. Security</span> <br />
                    We implement appropriate security measures to protect your information from unauthorized access and misuse.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>7. Children's Privacy</span> <br />
                    Our Website is not intended for users under the age of 18. We do not knowingly collect information from children.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>8. Changes to this Statement</span> <br />
                    We may update this Privacy and Cookies Statement periodically. The latest version will be posted on our Website with the effective date.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    <span>9. Contact Us</span> <br />
                    If you have questions about this Privacy and Cookies Statement or your data, please contact us at support@modonglobal.com.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                    By using our Website, you acknowledge that you have read and understood this Privacy and Cookies Statement.
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3">
                Modon Global
                </p>
                <p className="text-[#415553] text-[14px] lg:text-[16px] font-[400] md:mt-10 mt-3 mb-10">
                Remember that this is a generic template, and it should be customized to fit your specific website's policies and practices. Additionally, ensure that you comply with applicable data protection laws and regulations in your jurisdiction, such as GDPR in Europe or CCPA in California, and seek legal advice if needed.
                </p>
            </div>

        </>
    )
}
export default Privacy;