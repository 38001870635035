import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useWishlistStore } from '../../store/store';

function WishList() {
    const [navbar, setNavbar] = useState(false);
    const navigate = useNavigate();

    const [wishlistItems, setWishlistItems] = useState([]);
    console.log(wishlistItems)


    const wishlist = useWishlistStore((state) => state.wishlist);
    const getWishlist = useWishlistStore((state) => state.initiateWishlist);
    // const removeFromWishlist = useWishlistStore((state) => state.removeFromWishlist);

    const getWishlistDetails = () => {
        const token = localStorage.getItem('token');
    
        if (!token) {
            navigate('/login');
            return;
        }
    
        const userInfo = jwtDecode(token);
        getWishlist(userInfo.email); 

        console.log('User Info:', userInfo);
    
        fetch(`http://20.174.26.6:4000/api/wishlist/get-wishlish-info?userId=${userInfo.email}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            console.log('Response Status:', response.status);
            return response.json();
        })
        .then((details) => {
            console.log('API Response:', details);
            if (details.result) {
                setWishlistItems(details.result);
            } else {
                console.error('Unexpected response structure:', details);
            }
        })
        .catch((error) => {
            console.error('Error fetching wishlist details:', error);
        });
    };
    



    // 



    const removeFromWishlist = async (productId) => {
        try {
            const token = localStorage.getItem('token');
    
            if (!token) {
                console.error('User not authenticated');
                return;
            }
    
            const userInfo = jwtDecode(token);
    
            const response = await fetch('http://20.174.26.6:4000/api/wishlist/remove-from-wishlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId: userInfo.email,
                    product_Id: productId,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to remove item from wishlist');
            }
    
            // Remove the item from the wishlistItems state
            setWishlistItems((prevItems) => prevItems.filter(item => item.id !== productId));
    
            console.log('Item removed from wishlist successfully');
        } catch (error) {
            console.error('Error removing item from wishlist:', error.message);
        }
    };
    
    


    useEffect(() => {
        getWishlistDetails();
    }, [wishlist]);


    return (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bars'}>
            </nav>
            <div className="pt-36 px-5   mb-10 lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">
                <div className="flex justify-start p-2">

                    <span className="material-icons mr-2 cursor-pointer text-[#415553]">arrow_back</span>

                    {/* <input
                        type="text"
                        placeholder="Search adventures..."
                        className="px-5 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
                    /> */}



                    <Link to="/"><p className="text-center text-[#415553]  ml-2 mb-0">My wishlists</p></Link>

                </div>


                {wishlist.length === 0 ? (
                    <div className="flex flex-col items-center  justify-center mt-20">
                        <span className="material-icons mb-6 text-[200px] text-[#415553]">favorite</span>
                        <p className="text-center text-[#415553] mb-4 text-[52px]" >
                            Nothing here yet!
                        </p>
                        <p className="text-center text-[#415553] mb-4">
                            Select the heart icon to save experiences.
                        </p>
                        <Link to="/">
                            <button className="flex items-center justify-center bg-text-[#415553] text-white font-semibold py-2 px-4 rounded-md">
                                Explore Experiences
                            </button>
                        </Link>
                    </div>
                ) : (

                    <div className="mt-20  h-auto grid gap-4 grid-cols-3 md:grid-cols-2 lg:grid-cols-3">


                        {wishlistItems.map((item) => (


                            <div className='w-[300px] md:w-[380px] h-[490px] border-2 relative rounded-t-2xl rounded-b-2xl overflow-hidden'>
                                <div className='transition-all duration-300 transform  scale-100 hover:scale-105'>
                                    <img src={item.imageUrl} alt="" className='w-full h-[320px] rounded-t-2xl object-cover' />
                                    <div className="w-8 h-8 rounded-full absolute top-5 right-5 justify-center transition duration-500 ease-in-out cursor-pointer">
                                        < span
                                            onClick={() => removeFromWishlist(item.id)} className={`material-icons mt-1 ml-0.5 !text-[28px] text-[#415553] transition duration-300 ease-in-out ${item.id ? 'text-red-500' : 'text-gray-500'
                                                }`}
                                        >
                                            {item.id ? 'favorite' : 'favorite_border'}
                                        </span>


                                    </div>
                                </div>
                                <div className='mt-3 ml-3 absolute bottom-5'>
                                    <h1 className='text-[18px] font-medium text-[#415553] '>{item.countryname}</h1>
                                    <p className="mb-2 text-[18px] font-medium text-[#415553]">{item.title}</p>

                                    <p className='text-[14px] text-[#787878]'>{item.country}</p>
                                    <div className="rating">
                                        <input type="radio" name="rating-2" className="mask mask-star-2 bg-[#ffcd3c] h-4" />
                                        <input type="radio" name="rating-2" className="mask mask-star-2 bg-[#ffcd3c] h-4" />
                                        <input type="radio" name="rating-2" className="mask mask-star-2 bg-[#ffcd3c] h-4" />
                                        <input type="radio" name="rating-2" className="mask mask-star-2 bg-[#ffcd3c] h-4" />
                                        <input type="radio" name="rating-2" className="mask mask-star-2 bg-[#ffcd3c] h-4" /> &nbsp; 2300
                                    </div>
                                    <div className='grid grid-cols-2 space-x-6 mt-2'>
                                        <p className='text-[16px] text-red-600'>450<span className='text-[14px] text-[#415553]'> / Per Person</span></p>
                                        <button className='w-[100px] hover:bg-[#2A3B39] cursor-pointer h-11 border px-3 py-2 text-[#fff] bg-[#415553] rounded-lg'> Book Now</button>
                                    </div>
                                </div>
                            </div>

                        ))}

                    </div>

                )}

            </div>
        </>
    );
}

export default WishList;




