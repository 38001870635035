import React, { useEffect, useState } from "react";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [bannerSection, setBannerSection] = useState([]);
    const [staticSection, setStaticSection] = useState({});

    const homeStacticSection = () => (

        fetch(`http://20.174.26.6:4000/api/home/heroSection`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error("Unable to fetch data")
                }
            }).then((data) => {
                setBannerSection(data?.staticSections);
                setStaticSection(data?.staticSections[0])

            }).catch((err) => {
                console.log(err);
            })
    )


    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % staticSection?.image?.length);
        }, 7000);

        return () => clearInterval(interval);
    }, [staticSection?.image?.length]);

    useEffect(() => {
        const savedIndex = localStorage.getItem('heroBannerIndex');
        if (savedIndex !== null) {
            setCurrentImageIndex(parseInt(savedIndex, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('heroBannerIndex', currentImageIndex);
        homeStacticSection();
    }, [currentImageIndex]);

    return (
        <>
            <section className="relative w-full h-screen">
                {staticSection?.image?.map((image, index) => (
                    <img
                        key={index}
                        src={`./assets/backgrounds/${image?.imageUrl}`}
                        alt={`Slide ${index + 1}`}
                        className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                            }`}
                    />
                ))}

                <div className="absolute top-[35%] mx-5 md:mx-0 lg:left-[50%]  lg:transform lg:-translate-x-1/2">
                    <img src="/assets/shapes/banner-two__flotated-text.png" alt="" />
                </div>
                <div className='absolute top-0 grid  w-full justify-items-center content-center h-[70vh] '>
                    <h2 className='text-[28px] md:text-[58px] font-semibold capitalize text-[#fff]'>About Modon</h2>
                    <ul class="flex text-white font-bold md:text-[20px]">
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'>Home &nbsp;</li>/
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500 px-2'><span>Pages</span></li>/
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'><span>&nbsp;About Modon</span></li>
                    </ul>
                </div>
            </section>
            <div className="xl:px-[calc((100%-1200px)/2)] px-5 mt-10">
                <div className="lg:flex">
                    <div className="lg:w-[50%]">
                        <img src="assets/resources/cta-2-1.jpg" alt="" className="w-full" />
                    </div>
                    <div className="lg:w-[50%] lg:px-5">
                        <div className="">
                            <p className="text-[#415553] text-[18px] font-[600]  mt-5">About Modon</p>
                            <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">Welcome to Modon global, your gateway to unforgettable adventures and remarkable experiences. We're passionate about travel and exploration, and we're dedicated to helping you discover the world's wonders in the most convenient and exciting way possible.</p>
                        </div>
                        <div className="">
                            <p className="text-[#415553] text-[18px] font-[600]  mt-5">Our Mission</p>
                            <p className="text-[#415553] text-[16px] text-justify  lg:mt-2 mt-1">At Modonglobal, our mission is to inspire and empower travelers like you to embark on journeys that create lasting memories. We believe that travel is more than just a destination; it's about the people you meet, the cultures you embrace, and the stories you bring home.</p>
                        </div>
                        <div className="">
                            <p className="text-[#415553] text-[18px] font-[600]  mt-5">Why Choose Us?</p>
                            <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">Curated Experiences: We carefully curate a wide range of tours and activities, ensuring that each one offers a unique and authentic experience. From cultural excursions to outdoor adventures, we have something for every type of traveler.</p>
                            <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">Local Expertise: Our team of travel experts and local guides are passionate about sharing their knowledge and love for their regions. They provide you with insider tips, historical insights, and personalized recommendations.</p>
                            <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">User-Friendly Platform: We've designed our website to be user-friendly, making it easy for you to browse, book, and manage your travel experiences. Our intuitive search and booking system simplify the planning process.</p>
                            <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">Safety and Reliability: Your safety is our top priority. We work with trusted partners and operators who adhere to strict safety standards. We also provide detailed information about each tour's safety measures.</p>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="">
                        <p className="text-[#415553] text-[18px] font-[600]  mt-5">Our Commitment to Sustainability</p>
                        <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">We're committed to responsible travel and sustainable tourism practices. We partner with eco-conscious tour operators and support initiatives that minimize our environmental impact while preserving the beauty of our planet for future generations.</p>
                    </div>
                    <div className="">
                        <p className="text-[#415553] text-[18px] font-[600]  mt-5">Contact Us</p>
                        <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">We value your feedback and questions. If you need assistance or have any inquiries, our friendly customer support team is here to help. Feel free to reach out to us at support@modonglobal.com</p>
                    </div>
                    <div className="">
                        <p className="text-[#415553] text-[18px] font-[600]  mt-5">Join the Adventure</p>
                        <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">Whether you're a solo traveler seeking new horizons, a family looking for memorable experiences, or a group of friends in search of adventure, [Your Tour Website Name] is here to make your travel dreams a reality.</p>
                        <p className="text-[#415553] text-[16px] text-justify lg:mt-2 mt-1">Thank you for choosing us as your travel companion. We look forward to being a part of your journey as you explore the world and create unforgettable memories.</p>
                    </div>
                    <div className="text-[#415553] text-[18px] font-[600]  mt-5 mb-5">Start exploring today and let the adventure begin!</div>
                </div>
            </div>
            <div className="mb-14">
                {/* <img src="assets/backgrounds/video-bg-1-1.jpg" alt="" className="w-full fixed"/> */}
                <div className="parallax-1">
                    <div className="parallax-inner h-[500px] grid content-center bg-gray-200 bg-opacity-20	">
                        <div className="">
                            <p className="text-[#FFCC27] lg:text-[30px] text-[22px] text-center">Love where you're going</p>
                            <p className="lg:text-[68px] text-[36px] text-center text-white"><span className="font-[600]">Modon</span> is a World Leading <br />
                                Online <span className="font-[600]">Tour Booking Platform</span></p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bg-[#FFCC27] w-full xl:px-[calc((100%-1200px)/2)] px-5 h-[400px] grid content-center">
                <div className="lg:flex justify-between">
                    <div className="xl:w-[400px] lg:w-[380xpx]">
                        <p className="text-white xl:text-[50px] lg:text-[46px] text-[36px] font-[600]">Get Latest Tour Updates by Signing Up</p>
                    </div>
                    <div className="grid content-center">
                        <div className="lg:flex w-full justify-between mt-3">
                            <input type="text" className='lg:w-[400px] w-[280px] lg:h-[70px] md:h-[50px] h-[40px] bg-white text-[#] p-4' placeholder='E-mail Address' />

                            <input type="submit" className='md:ml-3 mt-3 lg:mt-0 lg:w-[200px] w-[140px] text-[14px] md:text-[20px] lg:text-[24px] font-[600] text-white h-[30px] md:h-[50px] lg:h-[70px] bg-[#415553] cursor-pointer hover:bg-[] transition-all' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default About;