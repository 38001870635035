import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrencyStore } from '../../store/store';


const Confirmation = () => {

    const [navbar, setNavbar] = useState(false);
    const [showMore, setShowMore] = useState(true);
    const navigate = useNavigate();
    const [orderConfirmationData, setOrderConfirmationData] = useState(null);
    console.log("orderconfirmation,", orderConfirmationData)
    const [currency, currencyRate] = useCurrencyStore((state) => [state.currency, state.currencyRate]);
    const [expandedItem, setExpandedItem] = useState(null);
    const [ticketUrl, setTicketUrl] = useState(null);
    const [productConfirmationCode, setProductConfirmationCode] = useState(null);
    console.log("productconirmationcode", productConfirmationCode)
console.log("ticj",ticketUrl)


    const toggleMore = (itemId) => {
        setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId));
    };

    // 

    const location = useLocation();
    const [confirmationCode, setConfirmationCode] = useState(null);
    console.log("nhh", confirmationCode)

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('confirmationCode');
        setConfirmationCode(code);

        if (code) {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            const userInfo = jwtDecode(token);

            fetch(`http://20.174.26.6:4000/api/order/order-confirmation?confirmationCode=${code}&userId=${userInfo.email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to fetch order confirmation data');
                    }
                })
                .then((data) => {
                    console.log('Order Confirmation Data:', data);
                    setOrderConfirmationData(data.result);
                    setTicketUrl(data.result.ticketUrl);
                    setProductConfirmationCode(data.result.productInvoice[0]?.productConfirmationCode);

                })
                .catch((error) => {
                    console.error('Error fetching order confirmation data:', error);
                });
        }
    }, [location.search]);
    //


    const handleCancelClick = () => {

        const token = localStorage.getItem('token');
        const userInfo = jwtDecode(token);

        fetch(`http://20.174.26.6:4000/api/bokun/cancel-product-booking?confirmationCode=${confirmationCode}&productConfirmationCode=${productConfirmationCode}&userId=${userInfo.email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
            .then((response) => {
                if (response.ok) {
                    console.log('Booking successfully canceled.');
                } else {
                    console.error('Failed to cancel booking.');
                }
            })
            .catch((error) => {
                console.error('Error cancelling booking:', error);
            });
    };

    return (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
            <section className="flex flex-wrap pt-32 pb-10 mx-5 h-auto md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">
                <div className='w-full h-auto border rounded-md'>
                    <div className='w-full h-[25vh] bg-[#E4EBF8]'>
                        <h1 className='px-10 pt-16 text-[28px] text-[#444444] text-center font-semibold'>Thank you for your booking!</h1>
                    </div>

                    <div className='block lg:flex gap-2 px-5'>
                        <div className='mt-10 md:px-5'>
                            <h1 className='text-[18px] text-[#444444] font-medium'>Your booking reference is  {confirmationCode}</h1>
                            <p className='text-[16px] text-[#444444] mt-2'>This experience is booked on request. The operator will confirm or reject your request directly.</p>

                            <h1 className='text-[18px] md:text-[20px] mt-5 font-semibold text-[#415553]'>You're booking</h1>
                            {orderConfirmationData && orderConfirmationData.productInvoice && orderConfirmationData.productInvoice.map((bookingItem, index) => (

                                <div key={index} className='flex h-auto px-2 w-full md:w-[600px] lg:w-[500px] xl:w-[600px] rounded-md gap-2 shadow-lg shadow-gray-300 '>

                                    <img src={bookingItem.productImageUrl} alt="" className='p-2 w-[100px] h-[100px] object-cover rounded-[10px] mt-2' />
                                    <div className='ml-4'>
                                        <h1 className='text-[16px] px-2 md:text-[18px] font-medium text-[#415553]'> {bookingItem.productTitle}</h1>
                                        <div className='grid grid-cols-1 md:grid-cols-3 md:p-2 justify-between md:space-x-6 text-[14px] text-[#415553]'>


                                            <ul className=' px-1 py-2' >
                                                <li>Travellers</li>
                                                {bookingItem.lineItems
                                                    .map((filteredItem) => (
                                                        filteredItem.quantity > 0 && (
                                                            <li key={filteredItem.pricingCategoryId}>
                                                                {filteredItem.quantity} {filteredItem.title}
                                                            </li>
                                                        )
                                                    ))}
                                            </ul>
                                            <ul className=' py-2 w-[150px]'>
                                                <li>Departure</li>
                                                <li>{bookingItem.dates}</li>
                                            </ul>
                                            <ul className='md:pl-10 py-2 '>
                                                <li>Duration</li>
                                                <li>{bookingItem.durationText}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                        <div>
                            <div className='w-full mt-10  md:w-[450px] h-auto p-3 rounded-md shadow-lg shadow-gray-300 mb-5'>
                                <span className='grid grid-cols-2'>
                                    <h1 className='text-[16px] md:text-[18px] font-medium text-[#415553]'>Order summary</h1>
                                    <h3 className='text-right text-[14px] text-[#415553]'>{orderConfirmationData?.productInvoice?.length ?? 0} item
                                    </h3>
                                </span>
                                <div className='w-full border h-[1px] '></div>
                                {orderConfirmationData && orderConfirmationData.productInvoice && orderConfirmationData.productInvoice.map((bookingItem, index) => (
                                    <>
                                        <div className=' p-2 mt-2'>
                                            <div className='grid grid-cols-3  gap-3'>
                                                <h1 className='text-[14px] md:text-[16px] max-w-full min-w-[150px]  font-medium text-[#415553] mt-5'> {bookingItem.productTitle}</h1>

                                                <h3 className='text-right mr-[-90px] text-[14px] text-[#415553]  mt-5'> {currency}   {bookingItem.totalAsMoney?.amount}
                                                </h3>

                                            </div>


                                            <p className='text-[14px] text-[#415553]'> {bookingItem.dates}</p>
                                            <div className={`mb-2 ${expandedItem === bookingItem.id ? 'block' : 'hidden'}`}>
                                                {bookingItem.lineItems
                                                    .map((filteredItem) => (
                                                        filteredItem.quantity > 0 &&
                                                        <div key={filteredItem.pricingCategoryId} className='grid grid-cols-2 mt-1'>
                                                            <h1 className='text-[14px] text-[#415553]'>    {filteredItem.title} x {filteredItem.quantity}</h1>
                                                            <h3 className='ml-[90px] text-[14px] text-[#415553] '>{currency} {filteredItem.totalDue}</h3>
                                                        </div>

                                                    ))}

                                            </div>
                                            <button className='text-[#8686f4] mt-5' onClick={() => toggleMore(bookingItem.id)}>
                                                {expandedItem === bookingItem.id ? 'Show Less' : 'Show More'}
                                            </button>
                                        </div>

                                        <div className='w-full h-[1px] border mt-2'></div>

                                        <div className='grid grid-cols-2 py-2 text-[14px] text-[#415553]'>
                                            <h3>Items</h3>
                                            <h3 className='text-right'>{currency}  {bookingItem.totalAsMoney?.amount} </h3>
                                        </div>
                                        <div className='w-full h-[1px] border'></div>
                                        <div className='grid grid-cols-2 text-[14px] text-[#415553] py-2 font-medium'>
                                            <h3>Total ({currency} )</h3>
                                            <h3 className='text-right'>{currency}  {bookingItem.totalAsMoney?.amount}</h3>
                                        </div></>
                                ))}




                            </div>

                            <div className='flex gap-4'>
                                <button
                                    className={`w-full h-10  text-[#415553] border-2 font-medium border-[#415553] mt-5 rounded-md text-[14px] md:text-[18px] `}

                                >
                                    Cancel Ticket
                                </button>
                                <button

                                    className={`w-full h-10 bg-[#415553] text-white font-medium mt-5 rounded-md text-[14px] md:text-[18px] `}

                                >
                                    <a
                                        href={ticketUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`w-full h-10 bg-[#415553] text-white font-medium mt-5 rounded-md text-[14px] md:text-[18px] `}
                                    >
                                        Download Ticket
                                    </a>                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='px-5 md:px-10'>
                        <h1 className='text-[18px] font-medium'>Customer Inforamtion</h1>
                        <h1>Name: {orderConfirmationData?.customerName}</h1>
                        <h3>Email: {orderConfirmationData?.customerEmail}</h3>

                    </div>
                    {/* <div className='px-5 md:px-10 mt-5 mb-5'>
                        <h1 className='text-[18px] font-medium'>Travel Documents</h1>
                        <a href='' className='text-[#8686f4]'>Invoice.pdf</a> <br />
                        <a href='' className='text-[#8686f4]'>A Romantic Journey in the City of Light.pdf</a>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default Confirmation;
