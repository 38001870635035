import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActivityStore, useCurrencyStore } from '../../store/store';
import { Link } from 'react-router-dom';



const Checkout = () => {
  const navigate = useNavigate();

  const [navbar, setNavbar] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({});
  const [expandedItem, setExpandedItem] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [checkIn, setCheckIn] = useState(false);
  const [jwtToken, setJwtToken] = useState();

  

  const [currency, currencyRate] = useCurrencyStore((state) => [state.currency, state.currencyRate]);
  // const [cart] = useActivityStore((state)=>[state.cart]);
  // const [cart, setCart] = useActivityStore((state) => [state.cart, state.set]);

  const { initiatecartlist, removefromcart } = useActivityStore();
  const [cart, isCartAffected] = useActivityStore((state) => [state.cart, state.isCartAffected]);

  console.log("userinfoemail", userDetails.email)
  console.log("cartqqqq", cart.length);

  const getBookingDetails = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login');
      return;
    }
    setJwtToken(token)
    const userInfo = jwtDecode(token);
    setUserDetails(userInfo);
    initiatecartlist(userInfo.email, token);

    fetch(`http://20.174.26.6:4000/api/booking/get-booking-details`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch booking details');
        }
      })
      .then((details) => {
        if (JSON.stringify(details) !== JSON.stringify(bookingDetails)) {
          setBookingDetails(details.result);
        }
      })
      .catch((error) => {
        console.error('Error fetching booking details:', error);
      });
  };




  useEffect(() => {
    getBookingDetails();
  }, [isCartAffected, currency]);


  const handleClick = () => {
    setCheckIn(!checkIn); // Toggle the checkIn state
  };


  const toggleMore = (itemId) => {
    setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId));
  };


  const removeFromCart = (itemId) => {

    removefromcart(itemId, bookingDetails?.userId, jwtToken)

    // fetch(`http://20.174.26.6:4000/api/cart/remove-from-cart?userId=${bookingDetails?.userId}&productId=${itemId}`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + jwtTokem,
    //   },
    // }).then((res)=>{
    //   return res.json();
    // }).then((data)=>{
    //   setBookingDetails(bookingDetails);
    //   console.log(data.message)
    // }).catch((error)=>{
    //   console.error('Remove From cart:', error);
    // })
    console.log(`Removing item with ID ${itemId} from the cart`);
  };



  const reserveAndConfirm = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    fetch('http://20.174.26.6:4000/api/bokun/reserve-and-confirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: userDetails.email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("confirm", response)

          return response.json();
        } else {
          throw new Error('Failed to reserve and confirm');
        }
      })
      .then((data) => {
        console.log(data);
        if (data.isSuccess) {
          const confirmationCode = data.result.confirmationCode;
          console.log("confirmationcode:",confirmationCode)
          navigate(`/confirmation?confirmationCode=${confirmationCode}`);
        }
       
      })
      .catch((error) => {
        console.error('Error reserving and confirming:', error);
      });
  };


  return (
    <>
      <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
      {bookingDetails && bookingDetails.productInvoice && bookingDetails.productInvoice.length > 0 && (

        <section className="flex flex-wrap pt-32 md:pt-44 mx-5 h-auto lg:h-screen md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">
          {cart.length === 0 ? (
            <p className="text-[#444444] text-[15px] mt-[100px]  text-center">
              Your Cart is empty.
            </p>
          ) : (
            <div className='grid grid-cols-1 lg:grid-cols-2 md:gap-20'>
              <div className="mb-4">
                <h1 className='text-[18px] md:text-[20px] font-semibold text-[#415553]'>You're booking</h1>
                {bookingDetails.productInvoice.map((bookingItem) => (
                  <div key={bookingItem.id} className='flex flex-row justify-between my-5 p-2 gap-2'>
                    <div className='block'>
                      <div className='flex h-auto px-2 w-full md:w-[600px] lg:w-[500px] xl:w-[600px] rounded-md gap-2 shadow-lg shadow-gray-300 '>
                        <img src={bookingItem.activity.originalImageUrl} alt="" className='w-[100px] h-[100px] object-contain rounded-[10px] mt-2' />
                        <div className='ml-4'>
                          <h1 className='text-[16px] px-2 md:text-[18px] font-medium text-[#415553]'>{bookingItem.activity.title}</h1>
                          <div className='grid grid-cols-1 md:grid-cols-3 md:p-2 justify-between md:space-x-6 text-[14px] text-[#415553]'>
                            <ul className=' px-1 py-2' >
                              <li>Travellers</li>
                              {bookingItem.lineItems
                                .map((filteredItem) => (
                                  filteredItem.quantity > 0 && (
                                    <li key={filteredItem.pricingCategoryId}>
                                      {filteredItem.quantity} {filteredItem.title}
                                    </li>
                                  )
                                ))}
                            </ul>
                            <ul className=' py-2 w-[150px]'>
                              <li>Departure</li>
                              <li>{bookingItem.dates}</li>
                            </ul>
                            <ul className='md:pl-10 py-2 '>
                              <li>Duration</li>
                              <li>{bookingItem.duration}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
                <div>
                  <h1 className='text-[16px] md:text-[18px] font-medium text-[#415553] mt-10'>Confirm</h1>
                  <div className='flex mt-5'>
                    <input
                      type="checkbox"
                      checked={checkIn}
                      onChange={handleClick}
                      className='w-5 h-5 mt-[2px]'
                    />
                    <p className='ml-2 text-[14px] text-[#787878]'>
                      I accept the <span className='text-[#415553]'>Cancellation Policy</span>
                    </p>
                  </div>
                 <button
                    className={`w-full h-10 bg-[#415553] text-white mt-5 rounded-md text-[14px] md:text-[18px] ${!checkIn ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={checkIn ? reserveAndConfirm : null}
                    disabled={!checkIn}
                  >
                    Reserve and Pay upon Arrival AED {bookingDetails.totalAsMoney}
                  </button>

                </div>
              </div>

              {/* Right side div */}
              <div className="w-full xl:w-[35%] md:ml-4 mt-5 md:mt-0">
                <div className='w-full md:w-[550px] lg:w-[450px] xl:w-[550px] h-auto p-3 rounded-md shadow-lg shadow-gray-300 mb-5'>
                  <span className='grid grid-cols-2'>
                    <h1 className='text-[16px] md:text-[18px] font-medium text-[#415553]'>Order summary</h1>
                    <h3 className='text-right text-[14px] text-[#415553]'> {bookingDetails.productInvoice.length} item</h3>
                  </span>
                  <div className='w-full border h-[1px] '></div>
                  {bookingDetails.productInvoice.map((bookingItem) => (
                    <div className=' p-2 mt-2' key={bookingItem.id}>


                      <div className='grid grid-cols-3  gap-3'>
                        <h1 className='text-[14px] md:text-[16px] max-w-[250px] min-w-[200px]  font-medium text-[#415553] mt-5'>{bookingItem.title}</h1>

                        <h3 className='text-right mr-[-90px] text-[14px] text-[#415553]  mt-5'> {currency} {bookingItem.totalAsMoney}</h3>
                        <button
                          className='text-[#545454] md:mr-[-150px] mt-10 md:mt-5'
                          onClick={() => removeFromCart(bookingItem.id)}
                        >
                          X                    </button>

                      </div>
                      <p className='text-[14px] text-[#415553]'> {bookingItem.dates}</p>
                      <div className={`mb-2 ${expandedItem === bookingItem.id ? 'block' : 'hidden'}`}>
                        {bookingItem.lineItems
                          .map((filteredItem) => (
                            filteredItem.quantity > 0 &&
                            <div key={filteredItem.pricingCategoryId} className='grid grid-cols-2 mt-1'>
                              <h1 className='text-[14px] text-[#415553]'>    {filteredItem.title} x {filteredItem.quantity}</h1>
                              <h3 className='ml-[90px] text-[14px] text-[#415553] '>{currency} {filteredItem.totalDue}</h3>
                            </div>

                          ))}

                      </div>
                      <button className='text-[#8686f4] mt-5' onClick={() => toggleMore(bookingItem.id)}>
                        {expandedItem === bookingItem.id ? 'Show Less' : 'Show More'}
                      </button>
                    </div>
                  ))}

                  <div className='w-full h-[1px] border mt-2'></div>
                  <div className='grid grid-cols-2 py-2 text-[14px] text-[#415553]'>
                    <h3>Items</h3>
                    <h3 className='text-right'>{currency} {bookingDetails.totalAsMoney}</h3>
                  </div>
                  <div className='w-full h-[1px] border'></div>
                  <div className='grid grid-cols-2 text-[14px] text-[#415553] py-2'>
                    <h3>Total ({currency})</h3>
                    <h3 className='text-right'>{currency}  {bookingDetails.totalAsMoney}</h3>
                  </div>
                  <div className='grid grid-cols-2 text-[14px] text-[#415553] py-2'>
                    <h3>Due now ({currency} )</h3>
                    <h3 className='text-right'>0</h3>
                  </div>
                  <div className='grid grid-cols-2 text-[14px] text-[#415553] py-2'>
                    <h3>Due on arrival ({currency} )</h3>
                    <h3 className='text-right'>{currency} {bookingDetails.totalAsMoney}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        // <div></div>

      )}
    </>
  );
};

export default Checkout;
