import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/outline";

const News = () => {
  const [navbar, setNavbar] = useState(false);

  const myData = [
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D ",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
    {
      img: "https://images.unsplash.com/photo-1616716343504-e2f71a52f809?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "14 Things to see and do when visiting Japan",
    },
  ];
  const ITEMS_PER_PAGE = 6;
  const totalItems = myData.length;

  const [activePage, setActivePage] = React.useState(1);

  const pageCount = Math.ceil(totalItems / ITEMS_PER_PAGE);

  // const getPageNumbers = () => {
  //   return Array.from({ length: pageCount }, (_, index) => index + 1);
  // };

  const next = () => {
    if (activePage === pageCount) return;

    setActivePage(activePage + 1);
  };

  const prev = () => {
    if (activePage === 1) return;

    setActivePage(activePage - 1);
  };

  const generatePageButtons = (totalPages) => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <IconButton
          key={i}
          onClick={() => setActivePage(i)} // Set active page on click
          className={`flex items-center justify-center border px-2 py-1 rounded-full ${
            activePage === i
              ? "bg-blue-100 border-blue-500 text-black"
              : "border-gray-300 hover:bg-blue-100 bg-gray-300"
          }`}
        >
          {i}
        </IconButton>
      );
    }
    return buttons;
  };

  const [bannerSection, setBannerSection] = useState([]);
  const [staticSection, setStaticSection] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const homeStacticSection = () =>
    fetch(`http://20.174.26.6:4000/api/home/heroSection`)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Unable to fetch data");
        }
      })
      .then((data) => {
        setBannerSection(data?.staticSections);
        setStaticSection(data?.staticSections[0]);
      })
      .catch((err) => {
        console.log(err);
      });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % staticSection?.image?.length
      );
    }, 7000);

    return () => clearInterval(interval);
  }, [staticSection?.image?.length]);

  useEffect(() => {
    const savedIndex = localStorage.getItem("heroBannerIndex");
    if (savedIndex !== null) {
      setCurrentImageIndex(parseInt(savedIndex, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("heroBannerIndex", currentImageIndex);
    homeStacticSection();
  }, [currentImageIndex]);

  return (
    <>
      {/* <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav> */}
      <section className="relative w-full h-screen">
        {staticSection?.image?.map((image, index) => (
          <img
            key={index}
            src={`./assets/backgrounds/${image?.imageUrl}`}
            alt={`Slide ${index + 1}`}
            className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
              index === currentImageIndex ? "opacity-100" : "opacity-0"
            }`}
          />
        ))}

        <div className="absolute top-[35%] mx-5 md:mx-0 lg:left-[50%]  lg:transform lg:-translate-x-1/2">
          <img src="/assets/shapes/banner-two__flotated-text.png" alt="" />
        </div>
        <div className="absolute top-20 grid  w-full justify-items-center content-center h-[70vh] ">
          <h2 className="text-[28px] md:text-[58px] font-semibold capitalize text-[#fff]">
            News
          </h2>
          <ul class="flex text-white font-bold md:text-[20px]">
            <li className=" hover:text-gray-800 transition-all cursor-pointer duration-500">
              HOME &nbsp;
            </li>
            /
            <li className=" hover:text-gray-800 transition-all cursor-pointer duration-500 px-2">
              <span>PAGES</span>
            </li>
            /
            <li className=" hover:text-gray-800 transition-all cursor-pointer duration-500">
              <span>&nbsp;NEWS</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="w-full h-auto mb-10">
        <div>
          <div className="flex justify-center items-center mb-10 mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-[50px] lg:gap-[30px] xl:gap-[50px]">
              {myData
                .slice(
                  (activePage - 1) * ITEMS_PER_PAGE,
                  activePage * ITEMS_PER_PAGE
                )
                .map((item, index) => (
                  <div
                    key={index}
                    className="border-2 w-[280px] md:w-[320px] lg:w-[300px] h-[480px] relative flex flex-col"
                  >
                    <div className="relative h-72 w-full overflow-hidden">
                      <img
                        src={item.img}
                        alt=""
                        className="absolute inset-0 h-full w-full object-center object-cover transition-transform duration-300 hover:scale-105"
                        style={{
                          transformOrigin: "center",
                        }}
                      />
                    </div>

                    <div class="flex flex-col justify-center items-center mt-5">
                      <ul class="flex space-x-3 text-[13px] w-[200px] cursor-pointer">
                        <li class="flex items-center hover:text-blue-500">
                          <img
                            width="15"
                            height="15"
                            src="https://img.icons8.com/windows/32/system-administrator-male.png"
                            alt="system-administrator-male"
                          />
                          <span class="ml-2">Admin</span>
                        </li>
                        <li class="flex items-center hover:text-blue-500">
                          <img
                            width="15"
                            height="15"
                            src="https://img.icons8.com/fluency-systems-regular/48/comments--v1.png"
                            alt="comments--v1"
                          />
                          <span class="ml-2">Comments</span>
                        </li>
                      </ul>
                      <h1 class="text-[20px] font-semibold text-black text-center p-6  hover:text-blue-500 cursor-pointer">
                        {item.title}
                      </h1>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex items-center gap-4 justify-center">
              <Button
                variant="text"
                className="flex items-center gap-2 px-2 py-1 rounded-md hover:bg-gray-100"
                onClick={prev}
                disabled={activePage === 1}
              >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4 text-black" />
              </Button>
              <div className="flex items-center gap-2">
                {generatePageButtons(pageCount)}{" "}
                
              </div>
              <Button
                variant="text"
                className="flex items-center gap-2 px-2 py-1 rounded-md hover:bg-gray-100"
                onClick={next}
                disabled={activePage === pageCount}
              >
                <ArrowRightIcon
                  strokeWidth={2}
                  className="h-4 w-4 text-gray-600"
                />
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
