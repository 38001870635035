import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {  Autoplay, FreeMode, Mousewheel, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCurrencyStore } from '../../store/store';
import { jwtDecode } from 'jwt-decode';

// Import Swiper styles

const LandingPage = (props) => {
  const navigate = useNavigate();

  const staticSection = props.staticSections;
  // const addToWishlist = props.addToWishlist;
  const removeFromWishlist = props.removeFromWishlist;
  const addToRecentlyViewed = props.addToRecentlyViewed;
  const token = localStorage.getItem('token');



  const bannerVideo = staticSection[1]?.video;

  const [adventure,setAdventure] = useState([]);
  const [destination,setDestination] = useState([]);

  const [currency, currencyRate] = useCurrencyStore((state) => [state.currency, state.currencyRate]);

  // const _unitPrice = ((destination ?? 0) * currencyRate).toFixed(2);/

  const AdventureInfo = () =>(

    fetch(`http://20.174.26.6:4000/api/home/adventureList`)
    .then((response)=>{
      if(response.status === 200){
        return response.json()
      }else{
        throw new Error("Unable to fetch data")
      }
    }).then((data)=>{
      setAdventure(data)
    }).catch((err)=>{
      console.log(err);
    })
  )

  const TrendingInfo = () =>(

    fetch(`http://20.174.26.6:4000/api/home/trendingList`)
    .then((response)=>{
      if(response.status === 200){
        return response.json()
      }else{
        throw new Error("Unable to fetch data")
      }
    }).then((data)=>{
      setDestination(data.result)
    }).catch((err)=>{
      console.log(err);
    })
  )
  useEffect(()=>{
    AdventureInfo();
    TrendingInfo();
  },[])
  


  // const handleFavoriteClick = (id) => {

  //   const updatedDestination = destination.map((item) =>
  //     item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
  //   );

  //   const selectedItem = updatedDestination.find((item) => item.id === id);

  //   if (!selectedItem.isFavorite) {
  //     removeFromWishlist(selectedItem);
  //   } else {
  //     addToWishlist(selectedItem);
  //   }

  //   setDestination(updatedDestination);
  // };


  

  const AddToWishlist = (selectedItem) => {
    const token = localStorage.getItem('token');
  
    if (token) {
      const userInfo = jwtDecode(token);
      console.log("hhhmm", userInfo);
  
      const dataToSend = {
        product_Id: selectedItem.id,
        userId: userInfo.email, 
      };
  
      fetch('http://20.174.26.6:4000/api/wishlist/add-to-wishlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (response.ok) {
            console.log("wishlistadded", selectedItem);
            return { success: true, product_Id: selectedItem.id, userId: userInfo };
          } else {
            throw new Error('Failed to add to wishlist');
          }
        })
        .catch((error) => {
          console.error('Error adding to wishlist:', error);
          return { success: false, product_Id: selectedItem.id, userId: userInfo };
        });
    } else {
      return Promise.reject('User not logged in');
    }
  };
  

 
  
  const handleFavoriteClick = (id) => {
    const updatedDestination = destination.map((item) =>
      item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
    );
  
    const selectedItem = updatedDestination.find((item) => item.id === id);
  
    if (selectedItem) {
      setDestination(updatedDestination);
  
      if (selectedItem.isFavorite) {
        AddToWishlist(selectedItem);
        console.log("jhgh",selectedItem)
      } else {
        removeFromWishlist(selectedItem);
      }
    } else {
      console.log('Item not found.');
    }
  };
  







// const handleFavoriteClick = (title) => {
//   const updatedDestination = destination.map((item) =>
//     item.title === title ? { ...item, isFavorite: !item.isFavorite } : item
//   );

//   const selectedItem = updatedDestination.find((item) => item.title === title);

//   if (selectedItem) {
//     if (!selectedItem.isFavorite) {
//       removeFromWishlist(selectedItem);
//     } else {
//       addToWishlist(selectedItem);
//     }

//     setDestination(updatedDestination);
//   } else {
//     console.log('Item not found.');
//   }
// };


  


  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const review =[
    {
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae dolor omnis iusto ea dolorem sit architecto a debitis totam nobis doloremque vero, ipsum alias saepe amet ad? Vel, dicta ipsam?",
      src: "/assets/testimonials/testimonials-1-1.jpg",
      name: "Kevin Smith"
    },
     {
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae dolor omnis iusto ea dolorem sit architecto a debitis totam nobis doloremque vero, ipsum alias saepe amet ad? Vel, dicta ipsam?",
      src: "/assets/testimonials/testimonials-1-2.jpg",
      name: "Kevin Smith"
    },
     {
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae dolor omnis iusto ea dolorem sit architecto a debitis totam nobis doloremque vero, ipsum alias saepe amet ad? Vel, dicta ipsam?",
      src: "/assets/testimonials/testimonials-1-3.jpg",
      name: "Kevin Smith"
    },
     {
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae dolor omnis iusto ea dolorem sit architecto a debitis totam nobis doloremque vero, ipsum alias saepe amet ad? Vel, dicta ipsam?",
      src: "/assets/testimonials/testimonials-1-1.jpg",
      name: "Kevin Smith"
    },
    {
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae dolor omnis iusto ea dolorem sit architecto a debitis totam nobis doloremque vero, ipsum alias saepe amet ad? Vel, dicta ipsam?",
      src: "/assets/testimonials/testimonials-1-2.jpg",
      name: "Kevin Smith"
    },
    {
      para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae dolor omnis iusto ea dolorem sit architecto a debitis totam nobis doloremque vero, ipsum alias saepe amet ad? Vel, dicta ipsam?",
      src: "/assets/testimonials/testimonials-1-3.jpg",
      name: "Kevin Smith"
    }
  ]


  const handleBookNow = (Id) => {
    const selectedDestination = destination.find((desti) => desti.Id === Id);
    addToRecentlyViewed(selectedDestination);
    navigate("/gettour")
  };


    // SwiperCore.use([Autoplay, Pagination, Navigation]);

  return (
    <>
    <div className='bg-white'>  

      <section className="relative top-12 mx-5 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1440px)/2)] lg:pr-[calc((100%-1440px)/2)]">
        <div className='h-auto w-full'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: false,
                }}
                modules={[Autoplay]}
                className="mySwiper carousel h-auto md:h-[300px] object-contain rounded-[30px]"
            >
            {bannerVideo?.map((video, index) => (
              <SwiperSlide className='mb-10 carousel-item'>
                <video key={index} width="100%" height="300px" autoPlay loop muted>
                  <source src={video.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className='mt-10'>
                <h1 className='text-[28px] text-[#415553] font-semibold'>Choose by Adventure</h1>

            <div className='mt-5 w-full h-auto flex justify-between space-x-2 carousel overflow-y-hidden' >
                  {adventure.map((Adventure, index) =>(
                    <div className='w-[285px] h-[252px] relative carousel-item' key={index} onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}>
                      <div className='absolute inset-0 bg-black bg-opacity-60 z-10 transition duration-300 ease-in-out rounded-2xl'></div>
                      <img src={Adventure.imageUrl} alt="" className='w-full object-cover rounded-2xl'/>
                      <div className='absolute bottom-10 left-2 w-full z-10 pb-4 transform translate-y-0 opacity-100'>
                        <h1 className={`text-white text-2xl uppercase font-semibold mb-0 slide-up ${hoveredIndex === index ? 'invisible' : ''}`}>
                          {Adventure.title}
                        </h1>
                        <div className={`grid grid-cols-2 space-x-3 absolute bottom-0 left-2 slide-up ${hoveredIndex === index ? '' : 'invisible'}`}>
                          <div className='block'>
                          <h3 className='text-white text-[20px] uppercase font-semibold mb-1 '>
                            {Adventure.title}
                          </h3>
                            <p className='text-[18px] text-[#ffffff]  font-semibold mb-1'>{Adventure.size} Tour</p>
                          </div>
                          <Link to={`/alltour?adventureId=${Adventure.id}`}>
                              <span class="material-icons material-symbols-outlined !text-[24px] text-[#fff] w-10 h-10 p-2 bg-[#FFB800] rounded-full cursor-pointer">
                            arrow_right_alt
                          </span></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
        </div>
      </section>

        <section className='h-auto w-full relative top-10'>
          <div className='mt-10'>
            <div className='mx-5 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1440px)/2)] lg:pr-[calc((100%-1440px)/2)]'>
            <h1 className='text-[28px] text-[#415553] font-semibold'>Trending Destinations</h1>
            </div>
            <div className='mt-5 mx-5 md:mx-0 md:ml-5' >
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                pagination={{
                  clickable: false,
                }}
                freeMode={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[ FreeMode, Autoplay]}
                breakpoints={{
                  750: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 2,
                  },
                  1055: {
                    slidesPerView: 4,
                    slidesPerGroup: 2,
                  }
                }

                }
                className="mySwiper carousel"
              >
                {destination.map((desti, index) => (
                  <SwiperSlide className='carousel-item flex pb-10'>
                    <div className='w-[300px] md:w-[320px] h-[490px] border-2 relative rounded-t-2xl rounded-b-2xl overflow-hidden'>
                      <div className='transition-all duration-300 transform scale-100 hover:scale-105'>
                        <img src={desti.imageUrl} alt="" className='w-full h-[320px] rounded-t-2xl object-cover' />
                        {token !=null &&
                        <div className="w-8 h-8 rounded-full absolute bg-none top-5 right-5 justify-center transition duration-500 ease-in-out cursor-pointer">
                       < span
                            onClick={() => handleFavoriteClick(desti.id)}
                            className={`material-icons mt-1 ml-0.5 !text-[28px] text-[#415553] transition duration-300 ease-in-out ${desti.isFavorite ? 'text-red-500' : 'text-[#f5f5f5]'
                              }`}
                          >
                            {desti.isFavorite ? 'favorite' : 'favorite_border'}
                          </span>


                        </div>
                        }
                      </div>
                      <div className='mt-3 ml-3 absolute bottom-5'>
                        <h1 className='text-[18px] font-medium text-[#415553]  truncate overflow-hidden max-w-[250px]' title={desti.title}>{desti.title}</h1>
                        <p className='text-[14px] text-[#787878]'>{desti.country}</p>
                        <div className="rating">
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <span key={item}>
                                    {item <= Math.floor(desti.rating) ? (
                                      <input
                                        type="radio"
                                        name="rating-1"
                                        className="mask mask-star bg-[#ffcd3c] h-4"
                                      />
                                    ) : (
                                      <input type="radio" name="rating-1" className="mask mask-star bg-[#c1c2c7] h-4"/>
                                    )}
                                  </span>
                                ))}
                                <p className='ml-3'>{desti.reviews}</p>
                              </div>
                        <div className='grid grid-cols-2 space-x-6 mt-2'>
                          <p className='text-[16px] text-red-600'>{currency} {(desti.amount * currencyRate).toFixed(0)}<span className='text-[14px] text-[#415553]'> / Per Person</span></p>
                          <Link to={`/gettour?tour=${desti.id}`}><h2 className='w-[100px] h-11 border px-3 py-2 text-[#fff] bg-[#415553] rounded-lg'>Book Now</h2></Link>
                        </div>
                      </div>
                    </div>  
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
      </section>
        <section className='mt-10 h-auto w-full bg-[#ECEEEF] relative'>
          <div className='mx-5 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1440px)/2)] lg:pr-[calc((100%-1440px)/2)]'>
            <p className='text-[20px] text-[#415553] text-center font-semibold pt-16'>Checkout Our top tour</p>
            <h1 className='text-[28px] text-[#415553] text-center font-semibold'>Reviews</h1>
            <div className='mt-5 pb-10' >
              <Swiper
                slidesPerView={1}
                spaceBetween={380}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                breakpoints={{
                  750: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 2,
                  },
                  1055: {
                    slidesPerView: 4,
                    slidesPerGroup: 2,
                  }
                }

                }
                className="mySwiper carousel"
              >
                {review.map((review, index) => (
                  <SwiperSlide className='carousel-item flex pb-10 '>
                    <div className='block relative w-[380px]'>
                    <div className='w-[380px] h-[250px] border-2 bg-white overflow-hidden'>
                      <p className='p-5 text-[16px] text-[#787878] text-justify tracking-tight'>{review.para}</p>
                    </div>
                    <div className='flex flex-col justify-center items-center align-middle mt-5 '>
                        <img src={review.src} alt=""  className='rounded-full '/>
                        <h1 className='text-[18px] text-[#415553]'>{review.name}</h1>
                    </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default LandingPage;
