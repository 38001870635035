import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Components/navbar';
import Home from './Components/home';
import Login from './Components/login';
import SignUp from './Components/signup';
import PersonalDetail from './Components/acc-setting/personal-detail';
import LoginInformation from './Components/acc-setting/login-info';
import PaymentMethod from './Components/acc-setting/payment';
import Notification from './Components/acc-setting/notify';
import Footer from './Components/footer';
import Recently_Viewed from './Components/acc-setting/recently_view';
import Reviews from './Components/acc-setting/review';
import AllTour from './Components/alltour';
import GetTour from './Components/gettour';
import { useState } from 'react';
import SiteSettings from './Components/acc-setting/sitesetting';
import Booked from './Components/acc-setting/booked';
import TravelAgent from './Components/travelagent';
import News from './Components/news';
import WishList from './Components/wishlist';
import Contact from './Components/acc-setting/contact';
import Privacy from './Components/acc-setting/privacy-statement';
import Sitemap from './Components/acc-setting/sitemap';
import About from './Components/acc-setting/about';
import Checkout from './Components/checkout';
import Confirmation from './Components/confirmpage';
import ReviewConfirmPage from './Components/reviewconfirmpage';

function App() {

  const [wishlist, setWishlist] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);


  const addToWishlist = (item) => {
    setWishlist([...wishlist, item]);
  };

  const addToRecentlyViewed = (item) => {
    setRecentlyViewed([...recentlyViewed, item]);
  };

  const removeFromWishlist = (itemId) => {
    const updatedWishlist = wishlist.filter((item) => item.title !== itemId);
    setWishlist(updatedWishlist);
  };

  return (

    <>
      <BrowserRouter>
        <Navbar wishlistItems={wishlist} />
        <Routes>
          <Route path='/' element={<Home addToWishlist={addToWishlist}
          removeFromWishlist={removeFromWishlist} addToRecentlyViewed={addToRecentlyViewed} />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/personal-detail' element={<PersonalDetail />} />
          <Route path='/login-information' element={<LoginInformation />} />
          <Route path='/payment-information' element={<PaymentMethod />} />
          <Route path='/notification' element={<Notification />} />
          <Route path='/alltour' element={<AllTour />} />
          <Route path='/recentlyreview' element={<Recently_Viewed />} />
          <Route path='/review' element={<Reviews />} />
          <Route path='/gettour' element={<GetTour />} />
          <Route path='/notification' element={<Notification />} />
          <Route path='/recentlyreview' element={<Recently_Viewed recentlyViewedList={recentlyViewed} />} />
          <Route path='/review' element={<Reviews />} />
          <Route path='/wishlist' element={<WishList wishlistItems={wishlist} removeFromWishlist={removeFromWishlist} />} />
          <Route path='/sitesetting' element={<SiteSettings />} />
          <Route path='/booked' element={<Booked wishlistItems={wishlist} removeFromWishlist={removeFromWishlist} />} />
          <Route path='/travelagent' element={<TravelAgent />} />
          <Route path='/news' element={<News/>}/>
          <Route path='/contact' element={<Contact/>}/>        
          <Route path='/privacy-statement' element={<Privacy/>}/> 
          <Route path='/sitemap' element={<Sitemap/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/confirmation' element={<Confirmation/>}/>
          <Route path='/reviewconfirmation' element={<ReviewConfirmPage/>}/>

          <Route path='/checkout' element={<Checkout />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
