import React, { useState } from 'react'
import SideMenu from '../side-menu'

const PaymentMethod = () => {

    const [navbar, setNavbar] = useState(false);

  return (
    <>
          <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
          <div className='grid grid-cols-1 md:grid-cols-2 pb-10 mx-5 md:mx-0 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-740px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]'>
              <div className='block mt-20 md:mt-[95px]'>
                  <SideMenu />
              </div>
              <div className="md:-ml-16 lg:-ml-52 xl:-ml-72 mt-5 md:mt-[118px] border rounded-[10px] p-10">
                  <div className='grid grid-cols-3'>
                      <h1 className='text-[16px] lg:text-[18px] font-semibold text-[#415553] mb-5'>Payment Method</h1>
                      <h1 className='text-[16px] font-semibold text-[#415553] mb-5  cursor-pointer'>Add</h1>
                      <h1 className='text-[16px] font-semibold text-[#415553] mb-5  cursor-pointer'>Edit</h1>
                  </div>
                  <form action='' className='flex flex-col space-y-2'>
                      <input type="text" placeholder='Card Holder Name ' className='w-full h-[48px] indent-2 md:indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md'/>
                      <input type="text" placeholder='Card Number' className='w-full h-[48px] indent-2 md:indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md'/>
                      <div className='block md:grid grid-cols-1 lg:grid-cols-3 lg:space-x-2'>
                          <input type="text" placeholder='Expity Month' className='w-full lg:w-[200px] h-[48px] indent-2 md:indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md'/>
                          <input type="text" placeholder='Expity Year' className='w-full lg:w-[200px] h-[48px] indent-2 md:indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md mt-2 lg:mt-0'/>
                          <input type="text" placeholder='CCV' className='w-full lg:w-[200px] h-[48px] indent-2 md:indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md mt-2 lg:mt-0'/>
                      </div>
                      <div className='block lg:flex flex-row lg:space-x-2'>
                          <input type="text" placeholder='IN' className='w-full lg:w-[200px] h-[48px] indent-2 md:indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md'/>
                          <input type="text" placeholder='0001' className='w-full lg:w-[200px] h-[48px] indent-2 md:indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md mt-2 lg:mt-0'/>
                      </div>
                  </form>
             </div>
            </div>
    </>
  )
}

export default PaymentMethod
