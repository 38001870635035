import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const SignUp = () => {
    const [navbar, setNavbar] = useState(false);

    // const [showPassword, setShowPassword] = useState(false);
    // const [confirmPassword, setconfirmPassword] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        createPassword: "",
        confirmPassword: ""
    });
    const [error, setError] = useState("");


    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.name === 'email' ? input.value.toLowerCase() : input.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setError('');
            const url = "http://20.174.26.6:4000/api/auth/signUp";
            const { data: res } = await axios.post(url, data);
            // router.push('/signin');
            setRegistrationSuccess(true); // Set registration success state
            console.log(res.message);

            console.log(res.message);
        } catch (err) {
            if (
                err.response &&
                err.response.status >= 400 &&
                err.response.status <= 500
            ) {
                setError(err.response.data.message);
            }
        }
    };

  return (
    <>
          <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
          <section className='py-36 px-5 lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)] grid grid-cols-1 lg:grid-cols-2 gap-20'>
              <div className='hidden lg:block'>
                  <img src="/assets/login/pngaaa 3.png" alt="" className='w-full mt-20' />
              </div>
              <div className='md:mt-5 flex flex-col justify-center items-center'>
                  <div className="lg:text-justify">
                      <h3 className='text-[28px] text-[#415553] font-semibold md:leading-[70px] lg:w-[350px]'>Signup and get exploring</h3>
                      <p className='text-[14px] md:text-[20px]  leading-8 text-[#415553]'>Log into your account with your email, or create one below. Quick and easy - promise!</p>
                  </div>
                  <form onSubmit={handleSubmit} className='mt-5 md:pr-5'>
                      <div className='grid grid-cols-1 lg:grid-cols-2 justify-between lg:space-x-2 lg:w-[450px] xl:w-[548px]'>
                          <input type="text" name="firstName" value={data.firstName} onChange={handleChange} placeholder='First Name' className='w-full lg:w-[215px] xl:w-[265px] h-[48px] indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md' />
                          <input type="text" name="lastName" value={data.lastName} onChange={handleChange} placeholder='Last Name' className='w-full lg:w-[215px] xl:w-[265px] h-[48px] indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md mt-2 lg:mt-0' />
                    </div>
                      <input type="email" name="email" value={data.email} onChange={handleChange} placeholder='Enter your Email' className='w-full lg:w-[450px] xl:w-[548px] h-[48px] indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md mt-2' />
                      <input type="password" name="createPassword" value={data.createPassword} onChange={handleChange} placeholder='Create Password' className='w-full lg:w-[450px] xl:w-[548px] h-[48px] indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md mt-2' />
                      <input type="password" name="confirmPassword" value={data.confirmPassword} onChange={handleChange} placeholder='Confirm Password' className='w-full lg:w-[450px] xl:w-[548px] h-[48px] indent-8 placeholder:text-[14px] border-2 border-[#787878] rounded-md mt-2' />
                      {/* <Link to='/login'>  */}
                      <button className='w-full lg:w-[450px] xl:w-[548px] h-[48px] bg-[#415553] p-2 text-[20px] text-[#fff] rounded-md mt-5'>Continue</button> 
                      {/* </Link> */}
                  </form>

                  {error && <p className="error-message text-red-600">{error}</p>}

                  {registrationSuccess && (
                      <div className=" fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-20  ">
                          <div className=" absolute justify-center lg:p-20 p-9 mx-5 md:mx-0 bg-white  w-full h-[400px] md:grid-cols-none md:w-[500px] md:h-[320px] lg:w-[650px] lg:h-[350px]  border rounded-[10px]  shadow-md">
                              <h2 className="text-[#444444] text-center text-[20px] mb-4">
                                  Thanks for the Registration!              </h2>
                              <div className="flex justify-center ">  <div className="w-12 h-12 rounded-full  border border-[#0CD0C6] text-center ">
                                  <span className="material-icons material-symbols-outlined text-[#0CD0C6]  !text-[30px] mt-2">
                                      done
                                  </span>
                              </div></div>

                              <div className="flex justify-center">
                                  <Link to="/login">
                                      <button className="text-[#11849D] font-medium"> Return to Sign In</button>
                                  </Link>
                              </div>
                          </div>

                      </div>
                  )}

                  <div className='text-center mt-2'>
                      <p className='text-[18px] font-medium leading-8 text-[#415553]'>or</p>
                      <div className='flex flex-row justify-center gap-5 align-middle mt-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
<path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
</svg>


<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
<linearGradient id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"></stop><stop offset="1" stop-color="#007ad9"></stop></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"></path><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"></path>
</svg>


<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
<linearGradient id="hlWX3vESs0eSKhHV_ppbBa_bCu25JrJ39EB_gr1" x1=".081" x2="50.487" y1="2.176" y2="52.582" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#262626" stop-opacity="0"></stop><stop offset="1" stop-color="#262626" stop-opacity=".8"></stop></linearGradient><path fill="url(#hlWX3vESs0eSKhHV_ppbBa_bCu25JrJ39EB_gr1)" d="M42.906,33.283c-1.034,2.289-1.529,3.313-2.857,5.336c-1.855,2.825-4.475,6.351-7.714,6.375	c-2.881,0.024-3.624-1.88-7.534-1.848c-3.91,0.019-4.724,1.883-7.608,1.854c-3.239-0.029-5.718-3.206-7.575-6.026	c-5.193-7.911-5.741-17.182-2.531-22.116c2.27-3.498,5.864-5.552,9.237-5.552c3.436,0,5.595,1.888,8.436,1.888	c2.756,0,4.435-1.893,8.409-1.893c3.005,0,6.183,1.637,8.454,4.463C34.195,19.834,35.401,30.444,42.906,33.283z"></path><linearGradient id="hlWX3vESs0eSKhHV_ppbBb_bCu25JrJ39EB_gr2" x1="17.19" x2="36.29" y1="-4.022" y2="15.078" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#262626" stop-opacity="0"></stop><stop offset="1" stop-color="#262626" stop-opacity=".8"></stop></linearGradient><path fill="url(#hlWX3vESs0eSKhHV_ppbBb_bCu25JrJ39EB_gr2)" d="M29.801,8.127c1.428-1.833,2.511-4.418,2.118-7.055c-2.33,0.159-5.054,1.646-6.645,3.573	c-1.441,1.754-2.637,4.36-2.171,6.881C25.644,11.607,28.274,10.093,29.801,8.127z"></path>
</svg>
                      </div>
                      <p className='text-[14px] leading-8 text-[#787878] mt-5'>By Sign In An Account, You Agree To Our Terms & Conditions, Privacy Policy And Agreement.</p>
                  </div>
              </div>
          </section>
    </>
  )
}

export default SignUp
