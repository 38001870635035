import React, { useEffect, useState } from 'react'
import SideMenu from '../side-menu';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';


function Reviews() {
  const [navbar, setNavbar] = useState(false);


  const [selectedRating, setSelectedRating] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 5;
  // const visiblePageNumbers = 5;


  const [visiblePageNumbers, setVisiblePageNumbers] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setVisiblePageNumbers(2);
      } else {
        setVisiblePageNumbers(5);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);






  const reviews = [
    { id: 1, rating: 5, text: 'Great experience!', name: "rafi", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },
    { id: 2, rating: 3, text: 'Average tour ghhbhggggggggggggggg  dsjbshb nnjdmsdjsb njdsjdh  bhsdgnnnnnnnnnnnnn ffffff.', name: "rafi", name: "rafi", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },
    { id: 3, rating: 2, text: 'Average tour.', name: "rafi", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },

    { id: 4, rating: 5, text: 'Average tour.', name: "rafiya", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },

    { id: 5, rating: 2, text: 'Average tour.', name: "fiya", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },

    { id: 6, rating: 5, text: 'Average tour.', name: "tnb", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },

    { id: 7, rating: 3, text: 'Average tour.', name: "rlm", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },

    { id: 8, rating: 2, text: 'Average tour.', name: "rafiyay", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },

    { id: 9, rating: 5, text: 'Average tour.', name: "rafi", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },

    { id: 10, rating: 4, text: 'Average tour.', name: "rafi", imageUrl: "/assets/team/team-1-1.jpg", date: "2 September 2023", description: "i like the area good i like to vist again , good place to visit must visit" },
    { id: 11, rating: 3, text: 'Nice place!', name: "Amanda", imageUrl: "/assets/team/team-1-1.jpg", date: "5 September 2023", description: "Enjoyed the visit, lovely experience." },
    { id: 12, rating: 4, text: 'Beautiful location!', name: "John", imageUrl: "/assets/team/team-1-1.jpg", date: "6 September 2023", description: "Great sights and wonderful atmosphere." },
    { id: 13, rating: 5, text: 'Fantastic tour!', name: "Emma", imageUrl: "/assets/team/team-1-1.jpg", date: "7 September 2023", description: "Absolutely loved the tour, would highly recommend it!" },
    { id: 14, rating: 2, text: 'Disappointing experience.', name: "Michael", imageUrl: "/assets/team/team-1-1.jpg", date: "8 September 2023", description: "Expected more, but it was quite underwhelming." },
    { id: 15, rating: 4, text: 'Enjoyable trip.', name: "Sophia", imageUrl: "/assets/team/team-1-1.jpg", date: "9 September 2023", description: "Had a great time exploring the area." },
    { id: 16, rating: 3, text: 'Average tour.', name: "Oliver", imageUrl: "/assets/team/team-1-1.jpg", date: "10 September 2023", description: "Not bad, not exceptional either." },
    { id: 17, rating: 5, text: 'Highly recommended!', name: "Isabella", imageUrl: "/assets/team/team-1-1.jpg", date: "11 September 2023", description: "One of the best tours I've been on!" },
    { id: 18, rating: 1, text: 'Terrible experience.', name: "William", imageUrl: "/assets/team/team-1-1.jpg", date: "12 September 2023", description: "Wouldn't suggest this tour to anyone." },
    { id: 19, rating: 4, text: 'Good tour.', name: "Emily", imageUrl: "/assets/team/team-1-1.jpg", date: "13 September 2023", description: "Enjoyed most parts of it." },
    { id: 20, rating: 3, text: 'Could be better.', name: "Daniel", imageUrl: "/assets/team/team-1-1.jpg", date: "14 September 2023", description: "Room for improvement." },
  ];

  // Calculate all count
  const ratingCount = {};
  reviews.forEach((review) => {
    if (!ratingCount[review.rating]) {
      ratingCount[review.rating] = 1;
    } else {
      ratingCount[review.rating]++;
    }
  });

  // review calculate percentage 

  const totalReviews = reviews.length;

  const totalRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
  const averageRating = totalRatings / totalReviews;



  // star fill
  const renderStars = () => {
    const fullStars = Math.floor(averageRating);
    const remainder = averageRating - fullStars;

    const starElements = [];

    const starStyle = {
      fontSize: '40px',
      FontFace:"bold"
    };

    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <span key={i} className="star" style={{ ...starStyle, color: '#FFA500' }}>
          ★
        </span>
      );
    }

    if (remainder > 0) {
      let gradientColor = `linear-gradient(to right, #FFA500 ${remainder * 100}%, #ccc ${remainder * 100}%)`;

      starElements.push(
        <span
          key="half"
          className="star"
          style={{
            ...starStyle,
            backgroundImage: gradientColor,
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          ★
        </span>
      );
    }

    const remainingStars = 5 - starElements.length;
    for (let i = 0; i < remainingStars; i++) {
      starElements.push(
        <span key={`empty-${i}`} className="star" style={{ ...starStyle, color: '#C0C0C0' }}>
          ★
        </span>
      );
    }

    return (
      <div  >
       <p > {starElements}</p>
      </div>
    );
  };



  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle the popup
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  //  filtering reviews 
  const handleRatingFilter = (rating) => {
    setSelectedRating(rating === selectedRating ? null : rating);
    setCurrentPage(1);
  };

  //   reviews pagena filter 
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const filteredReviews = reviews.filter(
    (review) => !selectedRating || review.rating === selectedRating
  );
  const currentReviews = filteredReviews.slice(indexOfFirstReview, indexOfLastReview);

  //  pagination 
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredReviews.length / reviewsPerPage); i++) {
    pageNumbers.push(i);
  }

  const displayPageNumbers = pageNumbers.slice(
    currentPage > Math.floor(visiblePageNumbers / 2)
      ? currentPage - Math.floor(visiblePageNumbers / 2)
      : 0,
    currentPage > Math.floor(visiblePageNumbers / 2)
      ? currentPage - Math.floor(visiblePageNumbers / 2) + visiblePageNumbers
      : visiblePageNumbers
  );





  return (
    <>
      <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
      <div className='mx-5 mb-6  md:mx-0  md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-740px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]'>
        <div className='grid grid-cols-1 md:grid-cols-2  '>
          <div className='block mt-20 md:mt-[95px]'>
            <SideMenu />
          </div>
          <div className="md:-ml-16  lg:-ml-52 xl:-ml-72 mt-5 md:mt-[118px] border rounded-[10px] p-10">

            <h1 className='text-[18px] font-semibold text-[#415553] '>Reviews
            </h1>

            <div className='grid grid-cols-1 lg:grid-cols-2'>
              {/* grid1 */}
              <div className='p-2 lg:w-[200px]'>

                <div className="rating  flex items-center">
                  <h1 className='text-[32px] font-bold text-[#415553] mr-2'>{averageRating.toFixed(1)} </h1>
                  <h1 className='text-[23px]  text-[#415553]'>{totalReviews} reviews</h1>

                </div>
                <div  className='mt-[-20px]'>
                {renderStars()}
                </div>



              </div>
              {/* grid2 */}
              <div>
                {/* <h1 className='text-[14px] text-[#444444] cursor-pointer'>Total review count and overall rating based on Tourist Trip reviews
                </h1> */}
                <div className="rating-sliders   ">
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <div key={rating} onClick={() => handleRatingFilter(rating)} className="range flex items-center w-full ">
                      <span className=" text-[10px] md:text-[12px]  xl:text-[18px] text-[#415553]  mr-2">Star {rating}: </span>
                      <div className="slider-container relative w-56 lg:w-40 xl:w-56 h-2 rounded-lg overflow-hidden">
                        <div className="bg-[#f5f5f5] w-full h-full absolute top-0 left-0"></div>
                        <div
                          className="slider bg-[#FFB800] absolute top-0 left-0 h-full rounded-lg"
                          style={{ width: `${(rating / 5) * 100}%` }}
                        ></div>
                        <input
                          type="range"
                          min="0"
                          max="5"
                          value={rating}
                          disabled
                          className="appearance-none w-full h-full"
                        />
                      </div>
                      <span className="ml-2 text-[10px] md:text-[12px] xl:text-[18px] text-[#415553]">{ratingCount[rating] || 0} members</span>
                    </div>
                  ))}
                </div>

              </div>
            </div>
            <div className=''>
              {/* <h1 className='text-[18px] font-semibold text-slate-800 '>Review by Modan Global.</h1>
              <h1 className='text-[18px] font-semibold text-slate-800 mt-2'>We perform <span className='underline cursor-pointer' onClick={togglePopup}>checks on reviews</span>
              </h1>

              <h1 className='text-[14px]  text-slate-800 mt-4'>Showing 1-3 of {totalReviews} reviews
              </h1>
 */}


              <div className='reviews mt-2'>
                {currentReviews.map((review, index) => (
                  <div key={review.id} className="customer-review mt-4 grid grid-cols-2 lg:grid-cols-3 gap-2" data-rating={review.rating}>
                    <div className=' flex items-center justify-center  w-1/6 -mx-1'>
                      <img src={review.imageUrl} alt={review.name} className=" w-auto h-auto rounded-full" />
                    </div>

                    <div className=' flex flex-col  justify-start w-[180px] -mx-[80px]  md:-mx-[140px] lg:-mx-[170px] xl:-mx-[200px]'>
                      <div className='flex '>
                        {[...Array(5)].map((_, i) => (
                          <span
                            key={i}
                            className="star"
                            style={{ color: i + 1 <= review.rating ? '#FFA500' : '#C0C0C0' }}
                          >
                            ★
                          </span>
                        ))}
                      </div>
                      <p className="text-start text-[14px] md:text-[14px] text-[#415553]">{review.name}</p>
                      <p className="text-start text-[12px] md:text-[12px] text-[#415553]">{review.date}</p>

                    </div>

                    <div className='  flex flex-col   justify-center w-[240px] md:w-[350px] xl:w-[550px] lg:-mx-[250px] xl:-mx-[300px]'>
                      <h3 className='text-[14px] md:text-[14px] text-[#415553]'>{review.text}</h3>
                      <p className='text-[14px] md:text-[12px] text-[#415553]'>{review.description}</p>
                    </div>
                  </div>
                ))}
              </div>




              {/* {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-20">
                  <div className="absolute justify-center lg:p-20 p-9 mx-5 md:mx-0 bg-white w-full h-auto md:grid-cols-none md:w-[500px] md:h-[320px] lg:w-[650px] lg:h-auto border rounded-[10px] shadow-md">
                    <button className="absolute top-0 right-0 m-3 focus:outline-none" onClick={togglePopup}>
                      <span className="text-xl text-gray-600 ">&times;</span>
                    </button>
                    <h2 className="text-[#444444] text-center text-[20px] mb-4">We perform checks on reviews</h2>

                    <div className="flex justify-center">
                      <p className=" text-[14px] leading-6">
                        You can only submit a review or rating of an experience to an automated tracking system, which collects information for each of the following criteria: who, e.g of an experience to an automated tracking system, which collects information for each of the following criteria: who, e.
                      </p>            </div>
                  </div>
                </div>
              )} */}

              {/* Pagination  */}
              <div className='pagination-container   bg-white flex justify-center '>
                {filteredReviews.length > reviewsPerPage && (
                  <div className='bottom-0 left-0 right-0 bg-white z-10 flex justify-center py-4'>
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`page-item  rounded-full border-2  p-2 mr-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed ' : 'hover:bg-gray-400'}`}
                    >
                      <BsChevronLeft className="" />
                    </button>

                    <ul className='pagination flex gap-2'>
                      {displayPageNumbers.map((number) => (
                        <li key={number} className={`page-item ${number === currentPage ? '' : ''} `}>
                          <button onClick={() => setCurrentPage(number)} className={`page-link py-2 px-4 text-[#415553]  ${number === currentPage ? 'text-gray-200' : 'hover:text-[#2A3B39]'}`}>
                            {number}
                          </button>
                        </li>
                      ))}
                    </ul>

                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === Math.ceil(filteredReviews.length / reviewsPerPage)}
                      className={`page-item  rounded-full  border-2 p-2 ml-2 ${currentPage === Math.ceil(filteredReviews.length / reviewsPerPage) ? 'opacity-50 cursor-not-allowed' : 'hover:text-[#2A3B39]'}`}
                    >
                      <BsChevronRight className="" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Reviews