import React, { useEffect, useState } from 'react';

const Loader = () => {
    const [isFlipped, setIsFlipped] = useState(false);

    useEffect(() => {
        const flipInterval = setInterval(() => {
            setIsFlipped((prev) => !prev);
        }, 1000); // Change the interval as needed (milliseconds)

        return () => clearInterval(flipInterval);
    }, []);

    return (
        <div className="block w-full h-full fixed top-0 left-0 z-50">
            <div className="loader-container w-full h-full flex flex-row justify-center items-center bg-[#364650]">
                <div className="loader">
                    <div className={`card ${isFlipped ? 'flipped' : ''}`}>
                        <div className="card-inner">
                            <img src="./assets/favicons/favi.png" alt="" className="card-front" />
                            <img src="./assets/favicons/favi01.png" alt="" className="card-back" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;
