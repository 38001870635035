import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCurrencyStore } from '../../store/store';
import Loader from '../loader/loader';



const AllTour = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('adventureId');
    console.log(query)
    const [navbar, setNavbar] = useState(false);

    const [productList, setProductList] = useState([]);

    const [currency, currencyRate] = useCurrencyStore((state) => [state.currency, state.currencyRate]);


    const tourProductInfo = () => (

        fetch(`http://20.174.26.6:4000/api/bokun/get-product-list?adventureId=${query}`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error("Unable to fetch data")
                }
            }).then((data) => {
                console.log(data.result)
                setProductList(data.result)
            }).catch((err) => {
                console.log(err);
            })
    )

    useEffect(() => {
        tourProductInfo();
    }, [])

    const adventureData = [
        {
            id: 1,
            title: 'A Romantic Journey in the City of Light',
            description: 'Discover the magic of our captivating day tour. Explore breathtaking landscapes, indulge in local flavours, and immerse yourself in the rich heritage of our destination.',
            price: 'AED 6000',
            day: "1 day",

            imageUrl: "./assets/tour/trend (6).png",
        },
        {
            id: 2,
            title: 'A Romantic Journey in the City of Light',
            description: 'Discover the magic of our captivating day tour. Explore breathtaking landscapes, indulge in local flavours, and immerse yourself in the rich heritage of our destination.',
            price: 'AED 600',
            day: "1 day",
            imageUrl: "./assets/tour/trend (2).png",
        },
        {
            id: 3,
            title: 'A Romantic Journey in the City of Light',
            description: 'Discover the magic of our captivating day tour. Explore breathtaking landscapes, indulge in local flavours, and immerse yourself in the rich heritage of our destination.',
            price: 'AED 6000',
            day: "1 day",

            imageUrl: "./assets/tour/trend (3).png",
        },
        {
            id: 4,
            title: 'A Romantic Journey in the City of Light',
            description: 'Discover the magic of our captivating day tour. Explore breathtaking landscapes, indulge in local flavours, and immerse yourself in the rich heritage of our destination.',
            price: 'AED 6000',
            day: "1 day",

            imageUrl: "./assets/tour/trend (4).png",
        },
        {
            id: 5,
            title: 'A Romantic Journey in the City of Light',
            description: 'Discover the magic of our captivating day tour. Explore breathtaking landscapes, indulge in local flavours, and immerse yourself in the rich heritage of our destination.',
            price: 'AED 6000',
            day: "1 day",

            imageUrl: "./assets/tour/trend (5).png",
        },
        {
            id: 6,
            title: 'A Romantic Journey in the City of Light',
            description: 'Discover the magic of our captivating day tour. Explore breathtaking landscapes, indulge in local flavours, and immerse yourself in the rich heritage of our destination.3',
            price: 'AED 6000',
            day: "1 day",

            imageUrl: "./assets/tour/trend (6).png",
        },
        {
            id: 7,
            title: 'Adventure 3',
            description: 'Description for Adventure 3',
            price: 'AED 6000',
            day: "1 day",

            imageUrl: "./assets/tour/trend (1).png",
        },
        {
            id: 8,
            title: 'Adventure 3',
            description: 'Description for Adventure 3',
            price: 'AED 6000',
            day: "1 day",

            imageUrl: "./assets/tour/trend (2).png",
        },
        // Add more adventure data as needed
    ];

    const [isloading, setIsloading] = useState(true);

    useEffect(() => {
        const fakeDatafetch = () => {
            setTimeout(() => {
                setIsloading(false);
            }, 2000)
        }
        fakeDatafetch();
    }, {})


    return isloading ? (
        <Loader />
    ) : (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bars'}>

            </nav>


            <div className="py-36 px-5  lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)] ">
                <div className="flex justify-start p-2">
                    <input
                        type="text"
                        placeholder="Search adventures..."
                        className="px-5 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
                    />
                </div>
                <div className=' mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:p-2 cursor-pointer'>
                    {productList.map((adventure) => (
                        <div key={adventure.id} className=" group  rounded-2xl overflow-hidden w-[300px] md:w-[320px] h-[490px] border-2 relative rounded-t-2xl rounded-b-2xl ">
                            <div className="relative">
                                <img
                                    className="w-full sm:h-48 object-cover transition duration-300  h-[360px]  rounded-t-2xl  transform group-hover:opacity-60 filter brightness-100"
                                    src={adventure.originalImageUrl}
                                    alt={adventure.title}
                                    style={{ height: '60%' }}
                                />
                                <div className="w-8 h-8 rounded-full absolute bg-none top-5 right-5 justify-center transition duration-500 ease-in-out cursor-pointer">
                                    < span
                                        className={`material-icons mt-1 ml-0.5 !text-[28px]  transition duration-300 ease-in-out  text-[#f5f5f5]
                                            }`}
                                    >
                                         favorite_border
                                    </span>


                                </div>
                                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300 bg-black bg-opacity-10">
                                    <Link to={`/gettour?tour=${adventure.productId}`}>    <button className="bg-[#415553] hover:bg-[#FFCC27] text-white font-semibold py-4 px-4 md:text-[18px] rounded-lg">
                                        Book Now
                                    </button></Link>
                                </div>
                            </div>
                            <div className="text-white  p-4  group-hover:bg-opacity-60 filter brightness-100">
                                <p className="text-[14px] md:text-[18px] text-[#565151] font-semibold mt-4">{adventure.title}</p>
                                <p className="text-gray-600 text-justify text-[14px] md:text-[16px] mt-4 leading-5">{adventure.excerpt}</p>
                                <div className="flex justify-between items-center mt-4">
                                    <p className="text-red-600 font-bold mt-2">{adventure.duration}</p>
                                    <p className="text-red-600 font-bold mt-2">{currency}  {(adventure.price * currencyRate).toFixed(0)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );

};

export default AllTour;
