import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useCurrencyStore } from '../../store/store';
import { useWishlistStore } from '../../store/store';
import Loader from '../loader/loader';



const Navbar = () => {

    const [navbar, setNavbar] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(false);
    const [showSideSearch, setShowSideSearch] = useState(false);
    const [userDetails,setUserDetails]= useState({});
    const searchBoxRef = useRef(null);

    const [loggedIn, setLoggedIn] = useState(false);
    console.log(loggedIn)
    const wishlist = useWishlistStore((state) => state.wishlist);

    useEffect(() => {
        // const token = sessionStorage.getItem('token');
       const token= localStorage.getItem('token');
        if (token != null) {
            const userInfo = jwtDecode(token);
            setUserDetails(userInfo);
            setLoggedIn(true)

            
        }

    }, []);

    const hasWishlistData = wishlist.length > 0;

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener('scroll', changeBackground);

    const handleSideMenu = () => {
        setShowSideMenu(!showSideMenu);
        console.log("chocks", showSideMenu);
    };

    const handleSideSearch = () => {
        setShowSideSearch(!showSideSearch);
        console.log("chocks", showSideSearch);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
                setShowSideSearch(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const dropdownRef = useRef(null);
    const [selectedCurrency, setSelectedCurrency] = useState('UAE');
    const [currencies, setCurrencies] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    // const currencies = ['UAE', 'India', 'UK'];
    const { currency, changeCurrency } = useCurrencyStore();


    const GetCurrencyList = () => (

        fetch(`http://20.174.26.6:4000/api/home/currencyList`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error("Unable to fetch data")
                }
            }).then((data) => {
                console.log(data.result)
                setCurrencies(data.result)
            }).catch((err) => {
                console.log(err);
            })
    )

    useEffect(() => {
        GetCurrencyList();
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectCurrency = (currency) => {
        changeCurrency(currency,userDetails.email);

        // setSelectedCurrency(currency);
        setIsOpen(false);
    };

    const [isloading, setIsloading] = useState(true);

    useEffect(() => {
        const fakeDatafetch = () => {
            setTimeout(() => {
                setIsloading(false);
            }, 5000)
        }
        fakeDatafetch();
    }, {})


    return isloading ? (
        <Loader />
    ) : (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bar'}>
                <div className=" w-full relative justify-between pl-5 pr-5 p-5 md:flex lg:px-[calc((100%-1200px)/2)] z-20">
                    <div className='flex justify-between space-x-2'>
                        <Link to='/'> <img src="/assets/logs.png" alt="" className='w-[250px] h-[35px] object-contain' /> </Link>
                        <div className='w-[1px] h-[35px] bg-[#fff] '></div>
                        <div className='mt-1 justify-center align-middle cursor-pointer' onClick={handleSideMenu}>
                           
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30" fill="#ffffff">
<path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z"></path>
</svg>
                        </div>

                    </div>
                    <div className='hidden md:flex space-x-2 lg:space-x-20 e cursor-pointer'>
                        <div className='flex justify-center  space-x-5 !text-[16px] p-3.5'>
                            {!loggedIn ? (
                                <Link to='/login'> <h1 className='text-[#fff] hover:text-[#FFB800]'>Login</h1> </Link>
                            ) : null}
                            {!loggedIn ? (
                                <Link to='/signup'><h1 className='text-[#fff] hover:text-[#FFB800]'>Sign up</h1> </Link>
                            ) : null}                        <span className="material-icon material-symbols-outlined !text-[#fff] hover:!text-[#FFB800]" onClick={handleSideSearch}>
                                search
                            </span>
                            {loggedIn ? (
                                <Link to='/personal-detail' className="material-icons !text-[#fff] hover:!text-[#FFB800]">
                                    person
                                </Link>
                            ) : null}
                            {loggedIn ? (
                                <Link to='/wishlist' className={`material-icons ${hasWishlistData ? 'text-[#FFB800]' : 'text-[#fff]'} !text-[24px] hover:!text-[#FFB800] transition duration-300 ease-in-out`}>
                                    {hasWishlistData ? 'favorite' : 'favorite_border'}
                                </Link>
                            ) : null}
                            <div className="relative">
                                <div className="right-0" role="menu" aria-orientation="vertical" aria-labelledby="currency-select">
                                    <select className=" font-semibold bg-transparent  text-[#fff]" value={currency} 
                                        onChange={(e) => selectCurrency(e.target.value)}
                                    >
                                        {currencies && currencies.map(c => (
                                            <option key={c} value={c} className="hover:bg-[#f1f1f1] text-[#444444]" >
                                                {c}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className='hidden float-right md:flex space-x-2 lg:space-x-20 uppercase cursor-pointer'>
                        <div className='flex justify-center space-x-5 text-[16px] p-3.5 font-bold'>


                            {!loggedIn ? (
                                <Link to='/login'> <h1 className='text-[#fff] hover:text-rose-700'>Login</h1> </Link>) : <></>}
                            {!loggedIn ? (
                           
                           
                           <Link to='/signup'><h1 className='text-[#fff] hover:text-rose-700'>Sign up</h1> </Link>) : <></>}

<div className='flex justify-center border space-x-2 !text-[16px] p-3.5'>
                            <span class="material-icon material-symbols-outlined !text-[#fff] hover:!text-rose-700" onClick={handleSideSearch}>
                                search
                            </span>
                            {loggedIn ? (
                                <Link to='/personal-detail' className="material-icons !text-[#fff] hover:!text-rose-700">
                                    person
                                </Link>
                            ) : null}

                            {loggedIn ? (
                                <Link to='/wishlist' className={`material-icons ${hasWishlistData ? 'text-red-500' : 'text-[#fff]'} !text-[24px] hover:!text-rose-700 transition duration-300 ease-in-out`}>
                                    {hasWishlistData ? 'favorite' : 'favorite_border'}
                                </Link>
                            ) : null}
                        </div>

                        </div>
                     

                        <div className="relative  text-center py-2">
                            {/* <div>
                                    <button
                                        type="button"
                                        onClick={toggleDropdown}
                                        className="inline-flex items-center justify-center w-full rounded-md text-[#fff] hover:!text-rose-700"
                                    >
                                        {selectedCurrency}
                                        <span className="ml-2 flex items-center">
                                            <span
                                                style={{ fontSize: '0.4rem', lineHeight: '1', marginTop: '1px', }}
                                            >
                                                {isOpen ? '▲' : '▼'}
                                            </span>
                                        </span>
                                    </button>
                                </div> 
                            <div className=" absolute right-0 " role="menu" aria-orientation="vertical" aria-labelledby="currency-select">

                                currencies.map((currency, index) => (
                                                <React.Fragment key={index}>
                                                    <a
                                                        href="#"
                                                        onClick={() => selectCurrency(currency)}
                                                        className="block px-2 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                        role="menuitem"
                                                    >
                                                        {currency}
                                                    </a>
                                                    {index !== currencies.length - 1 && <hr className="text-gray-500" />}
                                                </React.Fragment>
                                            ))} 
                                <select className="p-2 font-semibold bg-transparent ml-14 text-[#fff]" onChange={(e) => { console.log(e.target.value); }}>
                                    {currencies && currencies.map(c => (
                                        <option key={c} value={c} className="hover:bg-[#f1f1f1] text-[#444444]" >
                                            {c}
                                        </option>
                                    ))}
                                </select>

                            </div>

                        </div>
                    </div> */}



                </div>
            </nav>

            {/* SideMenu */}
            <div className={`block w-full shadow-inner fixed bg-[#364650bf] top-0 left-0 h-full p-5 z-50 transition-all duration-500 ease-in-out ${showSideMenu ? 'translate-x-0' : '-translate-x-full'}`}
            >
                <nav
                    className={!showSideMenu
                        ? "hidden"
                        : "block w-11/12 md:w-[40vw]  shadow-inner fixed bg-white  top-0 left-0 h-full p-5 z-50"
                    }
                >
                    <button className="absolute right-4 top-8" onClick={handleSideMenu}>
                        <span className="material-icons material-symbols-outlined !text-[24px] text-[#444444]">
                            close
                        </span>
                    </button>

                </nav>

            </div>


            {/* SlideSearch */}
            <div className={`block w-full shadow-inner fixed bg-[#364650bf] top-0 left-0 h-full p-5 z-50 transition-all duration-500 ease-in-out ${showSideSearch ? 'translate-y-0' : '-translate-y-full'}`} >

                <div className='w-full h-full flex flex-row justify-center items-center'>
                    <form className='flex' ref={searchBoxRef}>
                        <input type="text" placeholder='Type here to Search...' className='w-full md:w-[400px] h-[55px] indent-2 md:indent-8 placeholder:text-[18px]' />
                        <span class="material-icon material-symbols-outlined !text-[#fff] bg-[#415553] w-[60px] h-[55px] p-4">
                            search
                        </span>
                    </form>
                </div>
            </div>


        </>
    );
}

export default Navbar
