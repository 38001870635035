import React, { useState } from 'react';
import SideMenu from '../side-menu';

function SiteSettings() {
    const [navbar, setNavbar] = useState(false);
    const [editingLanguage, setEditingLanguage] = useState(false);
    const [editingCurrency, setEditingCurrency] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [selectedCurrency, setSelectedCurrency] = useState('USD');

    const handleSaveLanguage = () => {
        setEditingLanguage(false);

    };

    const handleSaveCurrency = () => {
        setEditingCurrency(false);

    };

    return (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
            <div className='grid grid-cols-1 md:grid-cols-2 mx-5 pb-10 md:mx-0 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-740px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]'>
                <div className='block mt-20 md:mt-[95px]'>
                    <SideMenu />
                </div>
                <div className="md:-ml-16 lg:-ml-52 xl:-ml-72 mt-5 md:mt-[118px] border rounded-[10px] p-10">
                    <h2 className='text-[18px] font-bold text-[#415553] mb-5'>Site Settings</h2>
                    <div>
                        {!editingLanguage ? (
                            <>
                                <div>
                                    <div className='grid grid-cols-2 mb-2'>
                                        {/* grid 1 */}
                                        <div>
                                            <span className='text-[#415553] font-medium'>Language</span>


                                        </div>
                                        {/* grid 2 */}
                                        <div className='text-end'>


                                            <button className='text-[#415553] font-medium' onClick={() => setEditingLanguage(true)}>Edit</button>



                                        </div>
                                    </div>
                                    <p>  {selectedLanguage}</p>


                                </div>

                                <hr className='mt-10' />
                            </>
                        ) : (
                            <div className='mt-10'>
                                <select className='w-full border p-2 '
                                    value={selectedLanguage}
                                    onChange={(e) => setSelectedLanguage(e.target.value)}
                                >
                                    <option value="English">English</option>
                                    <option value="Spanish">Spanish</option>
                                </select>
                                <div className='flex gap-4'>

                                    <button onClick={handleSaveLanguage} className='bg-[#415553] text-[#ffff] p-2 w-[60px]  hover:bg-[#2A3B39] cursor-pointer rounded mt-5 '>Save</button>
                                    <button onClick={() => setEditingLanguage(false)} className='bg-[#415553] text-[#ffff]  hover:bg-[#2A3B39] cursor-pointer p-2 w-[60px] rounded mt-5'>Cancel</button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='mt-10'>
                        {!editingCurrency ? (
                            <div>
                                <div className='grid grid-cols-2 mb-2'>
                                    <div>
                                        <span className='text-[#415553] font-medium'>  Currency</span>


                                    </div>
                                    {/* grid 2 */}
                                    <div className='text-end'>


                                        <button className='text-[#415553] font-medium' onClick={() => setEditingCurrency(true)}>Edit</button>



                                    </div>
                                </div>
                                <span>{selectedCurrency}</span>
                            </div>
                        ) : (
                            <div className='mt-10'>
                                <select
                                    value={selectedCurrency} className='w-full border p-2'
                                    onChange={(e) => setSelectedCurrency(e.target.value)}
                                >
                                    <option value="USD">USD</option>
                                    <option value="EUR">EUR</option>
                                </select>
                                <div className='flex gap-4'>
                                    <button onClick={handleSaveCurrency} className='bg-[#415553] text-[#ffff]  hover:bg-[#2A3B39] cursor-pointer p-2 w-[60px] rounded mt-5 '>Save</button>
                                    <button onClick={() => setEditingCurrency(false)} className='bg-[#415553] text-[#ffff]  hover:bg-[#2A3B39] cursor-pointer p-2 w-[60px] rounded mt-5 '>Cancel</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SiteSettings;
