import React, { useEffect, useState } from 'react'
import SideMenu from '../side-menu'
import { jwtDecode } from 'jwt-decode';


const PersonalDetail = () => {

        const [navbar, setNavbar] = useState(false);

        const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('token');  

      try {
        fetch(`http://20.174.26.6:4000/api/userInfo/getUserDetails`, {
          method: 'GET',
          headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json'
          },
      })
          .then((response) => {
              if (response.ok) {

                  return response.json();
              } else {
                  throw new Error('Failed to fetch booking details');
              }
          })
          .then((data) => {
              console.log("sc", data.result)
              setUserInfo(data.result);

          })
          .catch((error) => {
              console.error('Error fetching booking details:', error);
          });
      } catch (error) {
        console.log(error);
      }


  }, []);

        return (
            <>
                <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
          <div className='grid grid-cols-1 md:grid-cols-2 mx-5 pb-10 md:mx-0 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-740px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]'>
             <div className='block mt-20 md:mt-[95px]'>
                  <SideMenu />
              </div>
              <div className="md:-ml-16 lg:-ml-52 xl:-ml-72 mt-5 md:mt-[118px] border rounded-[10px] p-10">
                <div className='grid grid-cols-2'>
                <h1 className='text-[18px] font-semibold text-[#415553] mb-5'>Personal Information</h1>
                <h1 className='text-[16px] font-semibold text-[#415553] mb-5  hover:text-[#2A3B39]  cursor-pointer'>Edit</h1>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 font-semibold'>
                    <div className='flex flex-col space-y-[-20px]'>
                        <h1 className='text-[16px] font-medium text-[#415553] mb-5'>Name</h1>
                        <h1 className='text-[14px] text-[#415553] mb-5'>{userInfo.userName}</h1>
                    </div>

                    <div className='flex flex-col space-y-[-20px]'>
                        <h1 className='text-[16px] font-semibold text-[#415553] mb-5'>Email</h1>
                        <h1 className='text-[14px] text-[#415553] mb-5'>{userInfo.user_id}</h1>
                    </div>

                    <div className='flex flex-col space-y-[-20px] md:ml-[70px] lg:ml-0'>
                        <h1 className='text-[16px] font-semibold text-[#415553] mb-5'>Profile</h1>
                        <img src="/assets/blog/comment-1-2.jpg" alt=""  className='w-12 h-12 rounded-full object-fill'/>
                    </div>

                    <div className='flex flex-col space-y-[-20px] md:mt-5'>
                        <h1 className='text-[16px] font-semibold text-[#415553] mb-5'>Phone Number</h1>
                        <h1 className='text-[14px] text-[#415553] mb-5'>+91 7878787878</h1>
                    </div>

                    <div className='flex flex-col space-y-[-20px] md:mt-5'>
                        <h1 className='text-[16px] font-semibold text-[#415553] mb-5'>Country</h1>
                        <h1 className='text-[14px] text-[#415553] mb-5'>India</h1>
                    </div>
                </div>
              </div>
            </div>
    </>
  )
}

export default PersonalDetail
