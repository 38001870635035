
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';



function Booked() {
    const [navbar, setNavbar] = useState(false);

    const navigate = useNavigate();

    const [orderItems, setOrderItems] = useState([]);
    console.log(orderItems)
    const fetchData = async () => {
        const token = localStorage.getItem('token');
        console.log('Token:', token);

        if (!token) {
            navigate('/login');
            return;
        }

        const userInfo = jwtDecode(token);
        console.log('User Email:', userInfo.email);

        try {

            fetch(`http://20.174.26.6:4000/api/userInfo/get-user-booking-details?userId=${userInfo?.email}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    if (response.ok) {

                        return response.json();
                    } else {
                        throw new Error('Failed to fetch booking details');
                    }
                })
                .then((data) => {
                    console.log("sc", data.result)
                    setOrderItems(data.result);

                })
                .catch((error) => {
                    console.error('Error fetching booking details:', error);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);



    return (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bars'}>
            </nav>
            <div className="pt-36 px-5   mb-10 lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">
                <div className="flex justify-start p-2">

                    <span className="material-icons mr-2 cursor-pointer text-[#415553]">arrow_back</span>

                    {/* <input
                        type="text"
                        placeholder="Search adventures..."
                        className="px-5 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
                    /> */}



                    <Link to="/"><p className="text-center text-[#415553] ml-2 mb-0">Booking History</p></Link>

                </div>


                {orderItems.length === 0 ? (
                    <div className="flex flex-col items-center  justify-center mt-20">
                        {/* <span className="material-icons mb-6 text-[100px] text-gray-500">Booking History</span> */}
                        <p className="text-center text-[#415553] mb-4 text-[52px]">
                            Nothing here yet!
                        </p>
                        <p className="text-center text-[#415553] mb-4">
                            Book Now to Explore the experiences.
                        </p>
                        <Link to="/">
                            <button className="flex items-center justify-center bg-[#415553] text-white font-semibold py-2 px-4 rounded-md  hover:bg-[#2A3B39] cursor-pointer">
                                Explore Experiences
                            </button>
                        </Link>
                    </div>
                ) : (
                    <div className="flex flex-col mt-2">
                        <div className="grid grid-cols-1 mt-10 overflow-x-auto">
                            <table className="w-full table-auto overflow-x-scroll shadow-xl ">
                                <thead className="overflow-x-scroll">
                                    <tr className="bg-[#415553] text-[14px] text-left dark:bg-meta-4">
                                        <th className="min-w-[195px] text-[#fff] py-4 px-4 font-medium xl:pl-11">
                                            CONFIRMATION ID
                                        </th>
                                        <th className="min-w-[150px] text-[#fff] py-4 px-4 font-medium xl:pl-11">
                                            BOOKING ID
                                        </th>
                                        <th className="min-w-[150px] text-[#fff] py-4 px-4 font-medium ">
                                            DESTINATION
                                        </th>
                                        <th className="min-w-[150px] text-[#fff] py-4 px-4 font-medium">
                                            TOTAL AMOUNT
                                        </th>
                                        <th className="min-w-[150px] text-[#fff] py-4 px-4 font-medium">
                                            BOOKING STATUS                                        </th>
                                        <th className="min-w-[150px] text-[#fff] py-4 px-4 font-medium ">
                                            PAYMENT STATUS                                     </th>

                                        <th className="py-4  min-w-[150px] px-4 text-[#fff] font-medium">
                                            DATE                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderItems.map((item, index) => (

                                        <tr key={index} className=''>

                                            <td className="border-b border-[#eee] py-5 px-4 cursor-pointer  dark:border-strokedark xl:pl-11">
                                                <Link to={`/reviewconfirmation?confirmationCode=${item.productBookings[0].confirmationCode}`}>
                                                    <h5 className="font-medium " >
                                                        {item.productBookings[0].confirmationCode}                                              </h5>
                                                </Link>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4  dark:border-strokedark xl:pl-11">
                                                <h5 className="font-medium ">
                                                    {item.productBookings[0].bookingId}                                              </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className="">{item.productBookings[0].title}</p>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className="">
                                                    {item.currency} {item.productBookings[0].totalPrice}                                                </p>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className={`${item.status === 'CONFIRMED' ? 'text-green-500' :
                                                    item.status === 'PENDING' ? 'text-yellow-500' :
                                                        item.status === 'FAILED' ? 'text-red-500' : ''
                                                    }`}>
                                                    {item.status}
                                                </p>
                                            </td>

                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className={`${item.paymentStatus === ' PAID' ? 'text-green-500' :
                                                    item.paymentStatus === 'PENDING' ? 'text-yellow-500' :
                                                        item.paymentStatus === 'NOT_PAID' ? 'text-red-500' : ''
                                                    }`}>
                                                    {item.paymentStatus}
                                                </p>
                                            </td>

                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className="">
                                                    {item.productBookings[0].dateString}                                         </p>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>





                )}

            </div>
        </>
    );
}

export default Booked;
