import { React, useEffect, useState } from 'react';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LanguageIcon from '@mui/icons-material/Language';


const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [staticSection, setStaticSection] = useState({});
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const homeStacticSection = () => (
        fetch(`http://20.174.26.6:4000/api/home/heroSection`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json()
                }
                else {
                    return new Error("Unable to fetch data")
                }
            })
            .then((data) => {
                setStaticSection(data?.staticSections[0])
            }).catch((err) => {
                console.log(err);
            })
    )

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % staticSection?.image?.length);
        }, 7000);
        return clearInterval(interval);
    }, [staticSection?.image?.length]);

    useEffect(() => {
        const savedIndex = localStorage.getItem('heroBannerIndex');
        if (savedIndex !== null) {
            setCurrentImageIndex(parseInt(savedIndex, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('heroBannerIndex', currentImageIndex)
        homeStacticSection();
    }, [currentImageIndex]);
    return (
        <>
            <div className="">
                {/* <img
                    src={"./assets/backgrounds/2.png"}
                    alt=''
                    className={`relative top-0 left-0 right-0 w-full object-cover h-[70vh] transition-opacity opacity-100 duration-1000`}
                />                
                 */}
                <section className="relative w-full h-screen">
                    {staticSection?.image?.map((image, index) => (
                        <img
                            key={index}
                            src={`./assets/backgrounds/${image?.imageUrl}`}
                            alt={`Slide ${index + 1}`}
                            className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                                }`}
                        />
                    ))}

                    <div className="absolute top-[35%] mx-5 md:mx-0 lg:left-[50%]  lg:transform lg:-translate-x-1/2">
                        <img src="/assets/shapes/banner-two__flotated-text.png" alt="" />
                    </div>
                    <div className='absolute top-0 grid  w-full justify-items-center content-center h-[70vh] '>
                        <h2 className='text-[28px] md:text-[58px] font-semibold capitalize text-[#fff]'>Contact Us</h2>
                        <ul class="flex text-white font-bold md:text-[20px]">
                            <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'>HOME &nbsp;</li>/
                            <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500 px-2'><span>PAGES</span></li>/
                            <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'><span>&nbsp;CONTACT</span></li>
                        </ul>
                    </div>
                </section>
                <div className="xl:px-[calc((100%-1200px)/2)]  lg:py-16 grid lg:block justify-center">
                    <div className="grid lg:grid-cols-3 grid-cols-1 lg:ml-3 ml-0 xl:ml-0">
                        <div className="md:h-[200px] h-[160px] mt-5 xl:w-[370px] lg:w-[320px] md:w-[370px] w-[300px] border flex hover:shadow-2xl transition-all cursor-pointer">
                            <div className="ml-2 w-[80px] flex items-center justify-end h-full">
                                <div className="bg-[#FFCC27] rounded-[50%]"><img src="./assets/shapes/location.png" alt="" className='p-3 h-[60px] w-[60px]' /></div>
                            </div>
                            <div className="pl-5 w-[250px] grid content-center h-full">
                                <p className="md:text-[18px] text-[14px] text-[#9CA3A9] drop-shadow-2xl">#1704, Blue Bay Tower, Business Bay,
                                    Dubai, UAE Road New York. USA 6666</p>
                            </div>
                        </div>
                        <div className="md:h-[200px] h-[160px] mt-5 xl:w-[370px] lg:w-[320px] md:w-[370px] w-[300px] border flex hover:shadow-2xl transition-all cursor-pointer">
                            <div className="ml-2 w-[80px] flex items-center justify-end h-full">
                                <div className="bg-[#FFCC27] rounded-[50%]"><img src="./assets/shapes/phone.png" alt="" className=' p-3 h-[60px] w-[60px]' /></div>
                            </div>
                            <div className="pl-5 w-[250px] grid content-center h-full md:text-[18px] text-[14px] text-[#9CA3A9]">
                                <p>Local: <span className='hover:text-[#415553] transition-all cursor-pointer'>+52 106 1086</span></p>
                                <p>Mobile: <span className='hover:text-[#415553] transition-all cursor-pointer'>+52 106 1086</span></p>
                            </div>
                        </div>
                        <div className="md:h-[200px] h-[160px] mt-5 xl:w-[370px] lg:w-[320px] md:w-[370px] w-[300px] border flex hover:shadow-2xl transition-all cursor-pointer">
                            <div className="ml-2 w-[80px] flex items-center justify-end h-full">
                                <div className="bg-[#FFCC27] rounded-[50%]"><img src="./assets/shapes/mail.png" alt="" className=' p-3 h-[60px] w-[60px]' /></div>
                            </div>
                            <div className="pl-5 w-[250px] grid content-center h-full md:text-[18px] text-[14px] text-[#9CA3A9]">
                                <p><span className='hover:text-[#415553] transition-all cursor-pointer'>support@modonglobal.com</span></p>
                                <p><span className='hover:text-[#415553] transition-all cursor-pointer'>info@modonglobal.com</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="xl:px-[calc((100%-1200px)/2)] lg:px-10 mb-10 lg:flex px-5 mt-5 lg:mt-0">
                    <div className="lg:w-[40%]">
                        <p className="text-[16px] text-[#415553] font-[600] ">Contact With Us</p>
                        <p className="lg:text-[32px] text-[26px] py-3 pr-20 text-[#415553] font-[600] ">Have any Question?
                            feel free to contact
                            with us.</p>
                        <p className="text-[#9CA3A9] text-[18px] py-5">Your feedback and inquiries are important to us, and we're here to assist you.</p>

                        {/* <div className='h-[100px] w-[300px] flex justify-around'>
                            <InstagramIcon className='bg-[#ECEEEF] text-[#9CA3A9] p-4 hover:text-white hover:bg-[#40B9EB] transition-all cursor-pointer rounded-[50%] !h-[60px] !w-[60px]' />
                            <FacebookIcon className='bg-[#ECEEEF] text-[#9CA3A9] p-4 hover:text-white hover:bg-[#40B9EB] transition-all cursor-pointer rounded-[50%] !h-[60px] !w-[60px]' />
                            <TwitterIcon className='bg-[#ECEEEF] text-[#9CA3A9] p-4 hover:text-white hover:bg-[#40B9EB] transition-all cursor-pointer rounded-[50%] !h-[60px] !w-[60px]' />
                            <LanguageIcon className='bg-[#ECEEEF] text-[#9CA3A9] p-4 hover:text-white hover:bg-[#40B9EB] transition-all cursor-pointer rounded-[50%] !h-[60px] !w-[60px]' />
                        </div> */}
                    </div>
                    <div className="lg:w-[60%] px-2">
                        <form action="">
                            <div className="md:flex w-full justify-between mt-3">
                                <input type="text" className='w-full md:h-[60px] h-[40px]  bg-opacity-100 text-black p-4 border-2 border-[#787878] rounded-md' placeholder='Your Name' />
                                <input type="text" className='w-full md:h-[60px] h-[40px] mt-2 md:mt-0 md:ml-2  border-2 border-[#787878] bg-opacity-100 text-black p-4 rounded-md' placeholder='Your E-mail' />
                            </div>
                            <div className="md:flex w-full justify-between mt-3">
                                <input type="text" className='w-full md:h-[60px] h-[40px] border-2 border-[#787878] rounded-md bg-opacity-100 text-black p-4' placeholder='Phone Number' />
                                <input type="text" className='w-full md:h-[60px] h-[40px] mt-2 md:mt-0 md:ml-2 border-2 border-[#787878] rounded-md bg-opacity-100 text-black p-4' placeholder='Subject' />
                            </div>
                            <textarea name="" id="" cols="30" rows="10" className='w-full border-2 border-[#787878] rounded-md bg-opacity-100 text-black p-4 mt-3 h-[100px] md:h-[180px]' placeholder='Message'></textarea>
                            <input type="submit" className='md:w-[300px] w-[160px] text-[16px] md:text-[24px] font-[400] border-2  text-[#ffffff]  rounded-md h-[40px] md:h-[70px] bg-[#415553] mt-2 cursor-pointer hover:bg-[#2A3B39] transition-all' />
                        </form>
                    </div>
                </div>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" class="w-full h-[400px] mb-5" allowfullscreen></iframe>
            </div>
        </>
    )
}

export default Contact
