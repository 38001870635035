import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Recently_Viewed({ recentlyViewedList }) {
    const [navbar, setNavbar] = useState(false);
    console.log(recentlyViewedList)

    return (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bars'}>
            </nav>
            <div className="pt-36 px-5 mb-10 lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">
                <div className="flex justify-start p-2">
                    <span className="material-icons mr-2 cursor-pointer">arrow_back</span>
                    <Link to="/"><p className="text-center text-black ml-2 mb-0">Recently Viewed</p></Link>
                </div>

                <div className="mt-20 h-auto grid gap-4 grid-cols-1">
                    {recentlyViewedList && recentlyViewedList.length > 0 ? (
                        recentlyViewedList.map((item) => (
                            <div key={item.Id} className="border border-gray-300 rounded-md p-4 flex flex-col md:flex-row items-center justify-between">
 <h2 className="text-xl font-semibold mb-1">{item.countryname}</h2>
                                        <p className="mb-2">{item.title}</p>                            </div>
                        ))
                    ) : (
                        <div className="flex flex-col items-center mt-20 justify-center">
                            {/* JSX to display when recentlyViewedList is empty or undefined */}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Recently_Viewed;
