import React, { useEffect, useState } from "react";

const Sitemap = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [bannerSection, setBannerSection] = useState([]);
    const [staticSection, setStaticSection] = useState({});


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const homeStacticSection = () => (

        fetch(`http://20.174.26.6:4000/api/home/heroSection`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error("Unable to fetch data")
                }
            }).then((data) => {
                setBannerSection(data?.staticSections);
                setStaticSection(data?.staticSections[0])

            }).catch((err) => {
                console.log(err);
            })
    )


    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % staticSection?.image?.length);
        }, 7000);

        return () => clearInterval(interval);
    }, [staticSection?.image?.length]);

    useEffect(() => {
        const savedIndex = localStorage.getItem('heroBannerIndex');
        if (savedIndex !== null) {
            setCurrentImageIndex(parseInt(savedIndex, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('heroBannerIndex', currentImageIndex);
        homeStacticSection();
    }, [currentImageIndex]);

    return (
        <>
            <section className="relative w-full h-screen">
                {staticSection?.image?.map((image, index) => (
                    <img
                        key={index}
                        src={`./assets/backgrounds/${image?.imageUrl}`}
                        alt={`Slide ${index + 1}`}
                        className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                            }`}
                    />
                ))}

                <div className="absolute top-[35%] mx-5 md:mx-0 lg:left-[50%]  lg:transform lg:-translate-x-1/2">
                    <img src="/assets/shapes/banner-two__flotated-text.png" alt="" />
                </div>
                <div className='absolute top-0 grid  w-full justify-items-center content-center h-[70vh] '>
                    <h2 className='text-[28px] md:text-[58px] font-semibold capitalize text-[#fff]'>Sitemap</h2>
                    <ul class="flex text-white font-bold md:text-[20px]">
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'>HOME &nbsp;</li>/
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500 px-2'><span>PAGES</span></li>/
                        <li className=' hover:text-gray-800 transition-all cursor-pointer duration-500'><span>&nbsp;SITEMAP</span></li>
                    </ul>
                </div>
            </section>
            <div className="lg:px-[calc((100%-1200px)/2)] px-5 text-black">
                <p className="text-[28px] py-7">All Continents</p>
                <div className="pl-5">
                    <p className="text-[28px] mb-2">Tours</p>
                    <ul className="list-disc ml-10 text-[20px]">
                        <li className="mb-1">Asia</li>
                        <li className="mb-1">Australia & the Pacific</li>
                        <li className="mb-1">Caribbean</li>
                        <li className="mb-1">Central & South America</li>
                        <li className="mb-1">Europe</li>
                        <li className="mb-1">Middle East & Africa</li>
                        <li className="mb-3">North America</li>
                    </ul>
                </div>
                <div className="pl-5">
                    <p className="text-[28px] mb-2">Things To Do</p>
                    <ul className="list-disc ml-10 text-[20px]">
                        <li className="mb-1">Asia</li>
                        <li className="mb-1">Australia & the Pacific</li>
                        <li className="mb-1">Caribbean</li>
                        <li className="mb-1">Central & South America</li>
                        <li className="mb-1">Europe</li>
                        <li className="mb-1">Middle East & Africa</li>
                        <li className="mb-3">North America</li>
                    </ul>
                </div>
                <div className="pl-5">
                    <p className="text-[28px] mb-2">Attractions</p>
                    <ul className="list-disc ml-10 text-[20px]">
                        <li className="mb-1">Asia</li>
                        <li className="mb-1">Australia & the Pacific</li>
                        <li className="mb-1">Caribbean</li>
                        <li className="mb-1">Central & South America</li>
                        <li className="mb-1">Europe</li>
                        <li className="mb-1">Middle East & Africa</li>
                        <li className="mb-3">North America</li>
                    </ul>
                </div>
                <div className="pl-5">
                    <p className="text-[28px] mb-2">Attraction Tickets</p>
                    <ul className="list-disc ml-10 text-[20px]">
                        <li className="mb-1">Asia</li>
                        <li className="mb-1">Australia & the Pacific</li>
                        <li className="mb-1">Caribbean</li>
                        <li className="mb-1">Central & South America</li>
                        <li className="mb-1">Europe</li>
                        <li className="mb-1">Middle East & Africa</li>
                        <li className="mb-1">North America</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default Sitemap;