import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function TravelAgent() {
    const [navbar, setNavbar] = useState(false);

    const [bannerSection, setBannerSection] = useState([]);
    const [staticSection, setStaticSection] = useState({});


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const homeStacticSection = () => (

        fetch(`http://20.174.26.6:4000/api/home/heroSection`)
            .then((response) => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error("Unable to fetch data")
                }
            }).then((data) => {
                setBannerSection(data?.staticSections);
                setStaticSection(data?.staticSections[0])

            }).catch((err) => {
                console.log(err);
            })
    )


    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % staticSection?.image?.length);
        }, 7000);

        return () => clearInterval(interval);
    }, [staticSection?.image?.length]);

    useEffect(() => {
        const savedIndex = localStorage.getItem('heroBannerIndex');
        if (savedIndex !== null) {
            setCurrentImageIndex(parseInt(savedIndex, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('heroBannerIndex', currentImageIndex);
        homeStacticSection();
    }, [currentImageIndex]);



    const [showDiv, setShowDiv] = useState(false);

    const handleButtonClick = () => {
        setShowDiv(true);
    };


    const handleCloseClick = () => {
        setShowDiv(false);
    };





    // const [showPassword, setShowPassword] = useState(false);
    // const [confirmPassword, setconfirmPassword] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        createPassword: "",
        confirmPassword: ""



    });
    const [error, setError] = useState("");


    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.name === 'email' ? input.value.toLowerCase() : input.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setError('');
            const url = "http://20.174.26.6:4000/api/user/signUp";
            const { data: res } = await axios.post(url, data);
            // router.push('/signin');
            setRegistrationSuccess(true); // Set registration success state
            console.log(res.message);

            console.log(res.message);
        } catch (err) {
            if (
                err.response &&
                err.response.status >= 400 &&
                err.response.status <= 500
            ) {
                setError(err.response.data.message);
            }
        }
    };



    const tourReviews = [
        {
            id: 1,
            image: './assets/modontask/image 5.png',
            title: 'Convert traffic into revenue ',
            description: 'Earn money by promoting more than 28 million accommodations, plus transportation options and attractions in over 60,000 locations.',
        },
        {
            id: 2,
            image: './assets/modontask/image 6.png',
            title: 'Wide range of options            ',
            description: 'Earn money by promoting more than 28 million accommodations, plus transportation options and attractions in over 60,000 locations.',
        },
        {
            id: 3,
            image: './assets/modontask/image 7.png',
            title: 'Easy to use ',
            description: 'Earn money by promoting more than 28 million accommodations, plus transportation options and attractions in over 60,000 locations..',
        },
        {
            id: 4,
            image: './assets/modontask/image 8.png',
            title: 'Customer-driven service            ',
            description: 'Earn money by promoting more than 28 million accommodations, plus transportation options and attractions in over 60,000 locations.',
        },
    ];





    useEffect(() => {
        const toggleBodyOverflow = (show) => {
            document.body.style.overflow = show ? 'hidden' : 'auto';
        };

        toggleBodyOverflow(showDiv);

        return () => {
            toggleBodyOverflow(false);
        };
    }, [showDiv]);


    return (
        <>
            <nav className={navbar ? 'nav-bar active' : 'nav-bars'}></nav>
            <section className="relative w-full h-screen">
                {staticSection?.image?.map((image, index) => (
                    <img
                        key={index}
                        src={`./assets/backgrounds/${image?.imageUrl}`}
                        alt={`Slide ${index + 1}`}
                        className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                            }`}
                    />
                ))}

                <div className="absolute top-[35%] mx-5 md:mx-0 lg:left-[50%]  lg:transform lg:-translate-x-1/2">
                    <img src="/assets/shapes/banner-two__flotated-text.png" alt="" />
                </div>
                <div className="absolute top-[30%] md:top-[20%]  mx-5 lg:mx-0 lg:left-[40%] lg:transform lg:-translate-x-1/2">
                    <h2 className="text-[24px] md:text-[48px] font-bold capitalize text-white ">Partner with the leading platform for travel experiences</h2>
                    <p className="text-[16px] md:text-[18px] w-full lg:w-[600px]  font-medium leading-5 md:leading-8 capitalize text-justify text-white  mt-10">
                        Diverse and Curated Selection: Gain access to an extensive catalog of handpicked travel experiences and activities from around the world. From cultural immersions to thrilling adventures, we have it all.
                    </p>
                    <div className="mt-10 left-[0%] ">
                        <button className="p-4 bg-white w-[200px]  text-[16px] md:text-[18px] lg:w-[400px]   text-black rounded-md" onClick={handleButtonClick}>
                            Become an Affiliate
                        </button>
                    </div>
                </div>
            </section>

            {showDiv && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-auto">
                    <div className="absolute justify-center p-10 bg-white w-full mt-[200px] md-mt-auto h-auto md:h-auto  md:w-[500px] lg:w-[550px] border rounded-[10px] shadow-md">
                        <div className="flex justify-between mb-4">
                            <h2 className="text-[#444444] text-center text-[20px] font-semibold">
                                Sign up for our Partner program
                            </h2>
                            <button
                                onClick={handleCloseClick}
                                className="text-[#11849D] font-medium cursor-pointer"
                            >
                                X
                            </button>
                        </div>
                        <hr className="my-4 border-t border-gray-300" />

                        <form onSubmit={handleSubmit} className='mt-5 flex flex-wrap gap-4'>
                            <input
                                type="text"
                                name="firstname"
                                value={data.firstName}
                                onChange={handleChange}
                                placeholder='First Name'
                                className='w-full md:w-[calc(50% - 8px)] h-[55px] indent-8 placeholder:text-[18px] border-2 border-black rounded-md mt-2'
                            />
                            <input
                                type="text"
                                name="lastname"
                                value={data.lastName}
                                onChange={handleChange}
                                placeholder='Last Name'
                                className='w-full md:w-[calc(50% - 8px)] h-[55px] indent-8 placeholder:text-[18px] border-2 border-black rounded-md mt-2'
                            />
                            <input
                                type="email"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                                placeholder="Email"
                                className="w-full md:w-[calc(50% - 8px)] h-[55px] indent-8 placeholder:text-[18px] border-2 border-black rounded-md mt-2"
                            />
                            <input
                                type="password"
                                name="password"
                                value={data.password}
                                onChange={handleChange}
                                placeholder="Password"
                                className="w-full md:w-[calc(50% - 8px)] h-[55px] indent-8 placeholder:text-[18px] border-2 border-black rounded-md mt-2"
                            />
                            <input
                                type="password"
                                name="confirmPassword"
                                value={data.confirmPassword}
                                onChange={handleChange}
                                placeholder="Confirm Password"
                                className="w-full md:w-[calc(50% - 8px)] h-[55px] indent-8 placeholder:text-[18px] border-2 border-black rounded-md mt-2"
                            />
                            <button className='w-full md:w-[100%] lg:w-[100%] h-[55px] bg-[#415553] p-2 text-[20px] text-[#fff] rounded-md mt-5'>
                                Create an Account
                            </button>
                        </form>


                        {error && <p className="error-message text-red-600">{error}</p>}


                        <div className='text-center mt-2'>
                            <p className='text-[20px] font-semibold leading-8 text-[#565151]'>or continue with</p>


                            <div className='flex flex-row justify-center gap-5 align-middle mt-2'>
                                <img src="/assets/login/vector.png" alt="" />
                                <img src="/assets/login/Vector (1).png" alt="" />
                                <img src="/assets/login/Vector (2).png" alt="" />
                            </div>

                        </div>

                        <div className="flex mt-2 ">
                            <p className='text-[20px] font-semibold leading-6 text-[#565151]'> Already have an account?</p>
                            <Link to="/login">
                                <button className="text-[#11849D] font-medium text-[20px] leading-6">&nbsp; Log In</button>
                            </Link>
                        </div>
                        <p className='text-[20px] font-semibold leading-8 text-[#565151] mt-5'>By Sign In An Account, You Agree To Our Terms & Conditions, Privacy Policy And Agreement.</p>
                    </div>

                </div>
            )
            }





            <section className='w-full h-auto relative'>
                <div className='bg-white'>

                    <section className="relative top-12 mx-5 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">
                        <div className='h-auto w-full  text-center p-4'>
                            <h1 className='text-[40px] text-[#415553] font-semibold'>Join the Partner Program</h1>
                            <div className='mx-auto max-w-[600px] mt-10'>
                                <p className='text-[18px] text-[#444444] mb-1 mt-2'>
                                    Diverse and Curated Selection: Gain access to an extensive catalog of handpicked travel experiences and activities from around the world. From cultural immersions to thrilling adventures, we have it all.
                                </p>
                            </div>
                            <button className='py-3  px-8 bg-[#415553] text-white text-lg rounded-md mt-10 hover:bg-opacity-80' onClick={handleButtonClick}>
                                Sign Up For Free
                            </button>
                        </div>




                    </section>


                    <section className='mt-16 h-auto w-full bg-[#415553] relative'>
                        <div className='px-4 md:px-[calc((100%-740px)/2)] lg:px-[calc((100%-1200px)/2)]'>
                            <div className='mt-10 pb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
                                {tourReviews.map((tour) => (
                                    <div key={tour.id} className='flex justify-center md:justify-start overflow-hidden mt-10'>
                                        <div className='max-w-[300px]'>
                                            <img src={tour.image} alt={tour.title} className='object-cover' />
                                            <h2 className='text-xl font-semibold mb-2 truncate text-white'>{tour.title}</h2>
                                            <p className='text-white leading-10'>{tour.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>



                    <section className="relative top-12 mx-5 md:pl-[calc((100%-740px)/2)] md:pr-[calc((100%-768px)/2)] lg:pl-[calc((100%-1200px)/2)] lg:pr-[calc((100%-1200px)/2)]">

                        <div className='h-auto w-full mb-20  text-center p-4'>
                            <h1 className='text-[40px] text-[#415553] font-semibold'>Meet our successful partners
                            </h1>
                            <div className='mx-auto max-w-[600px] mt-10'>
                                <img src={"./assets/modontask/Rectangle 307.png"}
                                    alt='Image Description' className='mx-auto max-w-full h-auto rounded-md' />

                                <p className='text-[18px] text-[#444444] mb-1 mt-2'>
                                    Diverse and Curated Selection: Gain access to an extensive catalog of handpicked travel experiences and activities from around the world. From cultural immersions to thrilling adventures, we have it all.                                </p>
                            </div>

                        </div>


                    </section>
















                </div>




            </section>



        </>

    )
}

export default TravelAgent